import {Link} from "react-router-dom";
import {tassLogo} from "../../assets";
import {LinkButton} from "../buttons";
import {Bars3Icon, ShoppingCartIcon} from "@heroicons/react/24/outline";
import {ArchiveBoxIcon, ChevronDownIcon, HeartIcon, UserIcon} from "@heroicons/react/20/solid";

export const PublicHeader = () => {

    return (
        <>
            <nav
                className={"flex flex-wrap items-center justify-between px-2 bg-white shadow-lg shadow-slate-300 pb-2 md:py-2 z-50"}>
                <div className="container max-w-7xl px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div
                        className="w-full relative flex items-center justify-between md:w-auto md:static md:block md:justify-start">
                        <Link
                            className="leading-relaxed  mr-4 flex flex-col justify-center"
                            to="/"
                        >
                            <img src={tassLogo.img} className={"w-36"} alt={tassLogo.alt}/>
                        </Link>

                        <div className="block md:hidden text-white flex flex-row items-center">
                            <div className="">
                                <Bars3Icon className={"w-8 h-8"}/>
                            </div>
                        </div>
                    </div>
                    <div className="hidden w-full md:block md:w-auto flex justify-center items-center" id="navbar-default">
                        <ul className="flex flex-col items-center border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
                            <li>
                                <Link to={"/about"}>About TaaS</Link>
                            </li>
                            <li>
                                <Link to={"/join-the-network"}>Join Our Network</Link>
                            </li>
                            <li>
                                <Link to={"/hire-a-team"}>Hire a Team</Link>
                            </li>
                            <li className={"bg-red-600 text-white px-4 py-3 rounded-full"}>
                                <Link to={"/contact-us"}>Request a Demo</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}