import {Outlet, useLocation} from "react-router-dom";
import {AdminHeader} from "../../components/navs/AdminHeader";
import {AdminFooter} from "../../components/navs/AdminFooter";
import {AdminSidebar} from "../../components/navs/AdminSidebar";
import {useEffect} from "react";
import {fetchAllClients, fetchAllProjects} from "../../store/actions/adminActions";
import {errorHandling} from "../../store/actions/actionHelpers";
import {updateAdminData} from "../../store/reducers/adminDataReducer";
import {useDispatch, useSelector} from "react-redux";
import {checkIfLoggedIn} from "../../store/actions/authenticatedActions";
import {CheckIfLoggedOut} from "../../utils/checkIfLoggedIn";
import {resetPagination, updateForm} from "../../store/reducers/formsReducer";

export const AdminLayout = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const location = useLocation()

    useEffect(() => {
        if (state.adminData.forceUpdate) {
            fetchAllProjects()
                .then((res) => {
                    errorHandling(res.data)

                    switch (res.data.http_code) {
                        case 201:
                        case 200:
                            dispatch(updateAdminData({
                                section: "projects",
                                data: {
                                    name: "data",
                                    value: res.data.data
                                }
                            }))
                            break
                    }
                })

            fetchAllClients()
                .then((res) => {
                    errorHandling(res.data)

                    switch (res.data.http_code) {
                        case 201:
                        case 200:
                            dispatch(updateAdminData({
                                section: "clients",
                                data: {
                                    name: "data",
                                    value: res.data.data
                                }
                            }))
                            break
                    }
                })
        }
    }, [state.adminData.forceUpdate])
    useEffect(() => {
        dispatch(resetPagination())
    }, [location])

    return (
        <>
            <AdminHeader/>
            {/*<CheckIfLoggedOut />*/}
            <div className="grid grid-cols-[60px_1fr] overflow-hidden"  style={{height: "calc(100vh - 78px)"}}>
                <div className="relative h-full">
                    <AdminSidebar/>
                </div>
                <div className="py-4 px-2 w-full overflow-y-scroll scrollbar-thin scrollbar-thumb-red-600 scrollbar-track-slate-300">
                    <Outlet/>
                </div>
            </div>
            <AdminFooter/>
        </>
    )
}