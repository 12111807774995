import {useEffect, useState} from "react";
import {spinner} from "../../../assets";
import {StageOne} from "./stages/stageOne";
import {useNavigate, useParams} from "react-router-dom";
import {adminFetchProject} from "../../../store/actions/adminActions";
import {useDispatch, useSelector} from "react-redux";
import {errorHandling} from "../../../store/actions/actionHelpers";
import {emptyForm, setFormLoading, updateForm} from "../../../store/reducers/formsReducer";
import StageTwo from "./stages/stageTwo";
import StageThree from "./stages/stageThree";
import {StageFour} from "./stages/stageFour";
import {StageFive} from "./stages/stageFive";

export const AdminProjectView = () => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const {project_id} = useParams()

    useEffect(() => {
        dispatch(setFormLoading())
        adminFetchProject(parseInt(project_id.substring(8)))
            .then(result => {
                errorHandling(result.data)
                switch (result.data.http_code) {
                    case 201:
                    case 200:
                        dispatch(updateForm({
                            form: "project",
                            value: result.data.data
                        }))
                        break
                }
            })
    }, [project_id])

    useEffect(() => {
        dispatch(emptyForm("pool"))
    }, [state.forms.project.state])


    return (
        <div className={"min-h-screen relative"}>
            {
                state.forms.loading ?
                    <div className="h-full flex justify-center items-center">
                        <div className="animate-spin">
                            <img className={"w-20"} src={spinner.img} alt={spinner.alt}/>
                        </div>
                    </div>
                    :
                    <>
                        {
                            state.forms.project.state === 0 ?
                                <StageOne/>
                                : state.forms.project.state === 1 ?
                                    <StageTwo/>
                                    : state.forms.project.state === 2 ?
                                        <StageThree/>
                                        : state.forms.project.state === 3 ?
                                            <StageFour/>
                                            : state.forms.project.state === 4 ?
                                                <StageFive/>
                                        : null
                        }
                    </>
            }
        </div>
    )
}