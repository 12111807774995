import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    client: {
        company_logo: "",
        company_name: "",
        representative_first_name: "",
        representative_last_name: "",
        representative_email: "",
        country_code: "",
        representative_phone_number: "",
        representative_password: "",
    },
    project: {
        client_id: -1,
        project_name: "",
        description: "",
    },
    user: {},
    skills: [],
    pool: [],
    clients: [],
    page: 1,
    number_of_records: 20,
    loading: true
}

const formsSlice = createSlice({
    name: "forms",
    initialState: initialState,
    reducers: {
        updateForm__L1: (state, action) => {
            return {
                ...state,
                [action.payload.form]: {
                    ...state[action.payload.form],
                    [action.payload.data.name]: action.payload.data.value
                }
            }
        },
        setFormLoading: (state, action) => {
            return {
                ...state,
                loading: true
            }
        },
        updateForm: (state, action) => {
            return {
                ...state,
                [action.payload.form]: action.payload.value,
                loading: false
            }
        },
        emptyForms: (state, action) => {
            return {
                ...initialState
            }
        },
        emptyForm: (state, action) => {
            return {
                ...state,
                [action.payload]: []
            }
        },
        resetPagination: (state, action) => {
            return {
                ...state,
                page: 1,
                number_of_records: 20
            }
        },
        removeFromPool: (state, action) => {
            return {
                ...state,
                "pool": state.pool.filter(item => item.user_id !== action.payload)
            }
        }
    }
})

export const {
    updateForm__L1,
    emptyForm,
    emptyForms,
    updateForm,
    removeFromPool,
    setFormLoading,
    resetPagination
} = formsSlice.actions
export default formsSlice.reducer