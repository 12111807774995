import {error404} from "../../assets";
import {LinkButton} from "../../components/buttons";

export const  NotFound = () => {
    return(
        <div className={"py-20"}>
            <img src={error404.img} alt={error404.alt} className={"w-full lg:w-1/4  mx-auto"}/>
            <h4 className={"text-center font-bold text-lg mb-12"}>Looks Like the page you are looking for does not exist!</h4>
            <LinkButton location={"/"} className={"mx-auto  text-white bg-black "} size={"lg"}>Go to Homepage</LinkButton>
        </div>
    )
}