export const formatPrice = (price) => {
    return "₦ " + new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "NGN"
    }).format(price / 1).substring(4,);
}

export const addLeadingZeros = (num) => {
    return String(num).padStart(5, '0');
}

export const addEndingSpaces = (text) => {
    return String(text).padEnd(6, ' ');
}

export const generatePasswords = () => {
    let length = 12,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789:/.,$#!",
        retVal = "";

    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const parseProjectState = (state, user_type = 1) => {
    if (user_type === 2 || user_type === 1) {
        switch (state) {
            case 0:
                return "Pending Requirements"
            case 1:
                return "Approving Talent Pool Draft"
            case 2:
                return "Pending Client Approval"
            case 3:
                return "Making offers"
            case 4:
                return "Project Concluded"
            default:
                return "Contact system admin"
        }
    } else if (user_type === 3) {
        switch (state) {
            case 0:
                return "Pending Requirements"
            case 1:
                return "Approving Talent Pool Draft"
            case 2:
                return "Pending Approval of Recommended Talent"
            case 3:
                return "Making offers"
            case 4:
                return "Project Concluded"
            default:
                return "Contact system admin"
        }
    }
}

export const parseDateToId = (date) => {
    return new Date(date).toLocaleDateString(
        'en-gb',
        {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }
    ).replaceAll("/", "")
}

export const parseUserType = (type) => {
    switch (type) {
        case 1:
            return "admin"
        case 2:
            return "client"
        case 3:
            return "partner"
        case 4:
            return "talent"
    }
}

export const getTimeRange = (start, end) => {
    if (end <= start)
        return []
    let res = []

    for (let i = start; i < end + 1; i++)
        res.push(i)

    return res
}