import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import publicSettings from "./reducers/publicSettingsReducer";
import formsReducer from "./reducers/formsReducer";
import adminDataReducer from "./reducers/adminDataReducer";
export const store = configureStore({
    reducer: {
        auth: authReducer,
        publicSettings: publicSettings,
        forms: formsReducer,
        adminData: adminDataReducer
    }
})