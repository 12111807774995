import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {adminSearchAdmin, fetchAdmins} from "../../../store/actions/adminActions";
import {updateForm} from "../../../store/reducers/formsReducer";
import {Button, ButtonGroup} from "../../../components/buttons";
import {ColoredTextInput} from "../../../components/forms";
import Modal from "react-modal";
import {CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {spinner} from "../../../assets";

const customStyles = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "1200px",
        height: "85vh"
    },
};

export const AdminUserDashboard = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)
    const [query, setQuery] = useState("")
    const [isContenderDataOpen, setIsContenderDataOpen] = useState(false)

    useEffect(() => {
        dispatch(updateForm({loading: true}))
        if (query.length === 0)
            init()
        else {
            adminSearchAdmin({query: query, page: state.forms.page, number_of_records: state.forms.number_of_records})
                .then(res => {
                    dispatch(updateForm({
                        form: "pool",
                        value: res.data.data
                    }))
                })
        }
    }, [query, state.forms.page, state.forms.number_of_records])

    const init = () => {
        fetchAdmins({page: state.forms.page, number_of_records: state.forms.number_of_records})
            .then(res => {
                dispatch(updateForm({
                    form: "pool",
                    value: res.data.data
                }))
            })
    }

    const mountContender = (con) => {
        console.log(con)
        dispatch(updateForm({
            form: "user",
            value: con
        }))
        setIsContenderDataOpen(true)
    }

    return (
        <>
            <Modal
                isOpen={isContenderDataOpen}
                onRequestClose={() => setIsContenderDataOpen(false)}
                style={customStyles}
                contentLabel="Contender Data"
            >
                {
                    Object.keys(state.forms.user).length === 0
                        ? null :
                        <>
                            <div className="flex flex-row w-fit mx-auto mb-4 ">
                                <div
                                    style={{
                                        background: `url(${state.forms.user.avatar}) top/cover no-repeat`
                                    }}
                                    className="h-24 rounded-full bg-gray-400 w-24"/>
                                <div className="flex flex-col justify-center px-4 text-sm">
                                    <h4 className={"font-medium text-capitalize text-base"}>{state.forms.user.first_name + " " + state.forms.user.last_name}</h4>
                                    <h4>{state.forms.user.email}</h4>
                                    <h4>{state.forms.user.phone_number ? (state.forms.user.country_code + (state.forms.user.phone_number[0] === "0" ? state.forms.user.phone_number.substring(1) : state.forms.user.phone_number)) : "N/A"}</h4>
                                    <div className={"flex flex-row flex-wrap justify-center py-1"}>
                                        {
                                            state.forms.user.skills.map((item, key) =>
                                                <div
                                                    className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                    key={key}>
                                                    <span className="mr-1">{item.skill_name}</span>
                                                    <span className="mr-1">-</span>
                                                    <span className="">{item.years}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"h-full"}>
                                <object data={state.forms.user.resume} type="application/pdf" width="100%"
                                        height="100%">
                                    <p>Oops looks like something went wrong. Download this resume? <a
                                        href={state.forms.user.resume}>Click Here</a></p>
                                </object>
                            </div>
                        </>
                }
            </Modal>
            <div className={"px-24 py-16 flex flex-col justify-center items-center"}>
                <div className="flex flex-row items-center">
                    <div className="w-fit">
                        <ColoredTextInput label={"Search"} className={"w-96 mx-auto"}
                                          onChange={e => setQuery(e.target.value)}
                                          value={query}
                                          placeholder={"Looking for someone..."}/>
                    </div>
                    <ButtonGroup className={"mx-2"}>
                        <Button text={"New User"} size={"md"} bg={"bg-red-600"} color={"text-white"}/>
                    </ButtonGroup>
                </div>
                <div className="py-2 text-sm">
                    Users Listed: {state.forms.pool.length}
                </div>
            </div>
            <table className="table-auto w-full">
                <thead>
                <tr>
                    <th className={"px-1 text-left"}>ID</th>
                    <th className={"px-1 text-left"}>First Name</th>
                    <th className={"px-1 text-left"}>Last Name</th>
                    <th className={"px-1 text-left"}>Email</th>
                    <th className={"px-1 text-left"}>Phone</th>
                    <th className={"px-1 text-center"}>Type</th>
                    <th className={"px-1 text-center"}>Active</th>
                    <th className={"px-1 text-center"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    state.forms.loading ?
                        <div>
                            <img src={spinner.img} alt={spinner.alt} className="animate-rotate"/>
                        </div>
                        :
                        (state.forms.pool.map((item, key) =>
                            <tr key={key}>
                                <td className={"px-1"}>{((state.forms.page - 1) * state.forms.number_of_records) + (key + 1)}</td>
                                <td className={"capitalize px-1"}>{item.first_name}</td>
                                <td className={"capitalize px-1"}>{item.last_name}</td>
                                <td className={"px-1"}>{item.email}</td>
                                <td className={"px-1"}>{item.phone_number ? item.country_code + item.phone_number : "N/A"}</td>
                                <td className={"px-1 text-center"}>{item.user_type_id === 1 ? "Super" : "Admin"}</td>
                                <td className={"px-1"}>
                                    <CheckCircleIcon className={"w-5 h-5 text-green-600 mx-auto"}/>
                                </td>
                                <td>
                                    <ButtonGroup className={"justify-center"}>
                                        <Button onClick={() => mountContender(item)} size={"sm"} color={"text-white"}
                                                bg={"bg-green-600"}>View Data</Button>
                                        {/*<Button size={"sm"} color={"text-white"} bg={"bg-blue-600"}>Edit</Button>*/}
                                        {/*<Button size={"sm"} color={"text-white"} bg={"bg-red-600"}>Delete</Button>*/}
                                    </ButtonGroup>
                                </td>
                            </tr>
                        ))
                }
                </tbody>
            </table>
            <div className="grid grid-cols-3 my-2">
                <div className=""></div>
                <div className="flex flex-row justify-center">
                    <div
                        className="cursor-pointer bg-gray-200 flex items-center justify-center mx-2 px-3 py-2"
                        onClick={(e) => dispatch(updateForm({
                            form: "page",
                            value: state.forms.page > 1 ? state.forms.page - 1 : 1
                        }))}
                    >
                        <ChevronLeftIcon className={"w-4 h-4"}/>
                    </div>
                    <div className="bg-gray-200 flex items-center justify-center mx-2 px-3 py-2">
                        {state.forms.page}
                    </div>
                    <div
                        className="cursor-pointer bg-gray-200 flex items-center justify-center mx-2 px-3 py-2"
                        onClick={(e) => dispatch(updateForm({
                            form: "page",
                            value: state.forms.pool.length < state.forms.number_of_records ? state.forms.page : state.forms.page + 1
                        }))}
                    >
                        <ChevronRightIcon className={"w-4 h-4"}/>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <select
                        onChange={(e) => dispatch(updateForm({
                            form: "number_of_records",
                            value: e.target.value
                        }))}
                        value={state.forms.number_of_records}
                        className={"border outline-none rounded-lg bg-transparent py-2 px-3 w-16"}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                </div>
            </div>
        </>
    )
}