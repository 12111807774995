import Modal from 'react-modal';
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Title} from "../../../components/misc";
import {ColoredTextInput} from "../../../components/forms";
import {Button, ButtonGroup} from "../../../components/buttons";
import {useDispatch, useSelector} from "react-redux";
import {emptyForms, updateForm__L1} from "../../../store/reducers/formsReducer";
import {useEffect, useState} from "react";
import {generatePasswords} from "../../../utils/helpers";
import {createNewProject__NewClient, fetchAllSkills} from "../../../store/actions/adminActions";
import {uploadImage} from "../../../store/actions/authenticatedActions";
import {errorHandling} from "../../../store/actions/actionHelpers";
import {toast} from "react-toastify";
import {forceRefresh} from "../../../store/reducers/adminDataReducer";
import intlTelInput from "intl-tel-input";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: "600px"
    },
};

export const NewProjectNewCliForm = ({isOpen, setIsOpen, setIsProjectOpen}) => {

    const dispatch = useDispatch()
    const state = useSelector(state => state.forms)
    const [countryCodes, setCountryCodes] = useState([])
    const [errors, setErrors] = useState(false)
    const [mount, setMount] = useState(false)

    useEffect(() => {
        if (isOpen) {
            fetchAllSkills()
                .then(
                    () => {
                        setMount(true)
                    })
        } else
            setMount(false)
    }, [isOpen])
    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        dispatch(emptyForms())
        setErrors(false)
    }

    const processNewProject = (e) => {
        e.preventDefault()
        let cc = document.querySelector("#phone").parentElement.childNodes[0].childNodes[0]
        console.log(state.client)
        // return;

        if (
            state.client.company_logo.length === 0 ||
            state.client.company_name.length === 0 ||
            state.client.representative_first_name.length === 0 ||
            state.client.representative_last_name.length === 0 ||
            state.client.representative_email.length === 0 ||
            state.client.representative_phone_number.length === 0 ||
            state.client.representative_password.length === 0 ||
            state.project.project_name.length === 0
        ) {
            setErrors(true)
            toast.error("Please check highlighted fields for errors")
        } else {
            setErrors(false)

            createNewProject__NewClient({
                client: {...state.client,  representative_country_code: cc.title.substring(cc.title.indexOf(":") + 2)
                },
                project: state.project
            }).then(res => {
                errorHandling(res.data)

                switch (res.data.http_code) {
                    case 201:
                    case 200:
                        toast.success(res.data.message)
                        break
                }
            }).finally(() => {
                dispatch(emptyForms())
                dispatch(forceRefresh())
                closeModal()
            })
        }
    }

    const uploadCompanyLogo = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append("logo", e.target.files[0])

        uploadImage(formData)
            .then(res => {
                updateForm("client", "company_logo", res.data.data.filename)
            })
    }

    const updateForm = (form, name, value) => {
        dispatch(updateForm__L1({
            form: form,
            data: {
                name: name,
                value: value
            }
        }))
    }

    useEffect(() => {
        if (mount) {
            const input = document.querySelector("#phone");
            intlTelInput(input, {
                customContainer: "w-full",
                initialCountry: "NG",
                preferredCountries: ["NG", "GH", "US", "GB"]
            });
        }
    }, [mount])

    return (
        <div>
            <Modal
                isOpen={isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="New Project Form"
            >
                <button className={"ml-auto block cursor-pointer w-fit"} onClick={closeModal}>
                    <XMarkIcon className={"w-6 h-6"}/>
                </button>

                <Title>New Project (New Client)</Title>
                <br/>
                <br/>
                <form onSubmit={e => processNewProject()}>
                    <div className="mb-8 grid grid-cols-2 gap-2">
                        <ColoredTextInput
                            required={true}
                            type={"file"}
                            label={"Company Logo"}
                            className={(errors && state.client.company_logo.length === 0) ? "border-red-600" : ""}
                            onChange={e => uploadCompanyLogo(e)}
                        />
                        <ColoredTextInput
                            required={true}
                            type={"text"}
                            className={(errors && state.client.company_name.length === 0) ? "border-red-600" : ""}
                            value={state.client.company_name || ""}
                            label={"Company Name"}
                            onChange={e => updateForm("client", "company_name", e.target.value)}
                        />
                    </div>
                    <div className="mb-8 grid grid-cols-2 gap-2">
                        <ColoredTextInput
                            required={true}
                            type={"text"}
                            className={(errors && state.client.representative_first_name.length === 0) ? "border-red-600" : ""}
                            value={state.client.representative_first_name || ""}
                            label={"First Name"}
                            onChange={e => updateForm("client", "representative_first_name", e.target.value)}
                        />
                        <ColoredTextInput
                            required={true}
                            type={"text"}
                            className={(errors && state.client.representative_last_name.length === 0) ? "border-red-600" : ""}
                            value={state.client.representative_last_name || ""}
                            label={"Last Name"}
                            onChange={e => updateForm("client", "representative_last_name", e.target.value)}
                        />
                    </div>
                    <div className="mb-8 grid grid-cols-2 gap-2">
                        <ColoredTextInput
                            required={true}
                            type={"text"}
                            className={(errors && state.client.representative_email.length === 0) ? "border-red-600" : ""}
                            value={state.client.representative_email || ""}
                            label={"Email"}
                            onChange={e => updateForm("client", "representative_email", e.target.value)}
                        />
                        <div className="">
                            <input
                                name={"phone"}
                                id={"phone"}
                                value={state.client.representative_phone_number || ""}
                                className={
                                    "w-full transition-all duration-300 w-full outline-none px-4 py-3 rounded border " +
                                    (errors && state.client.representative_phone_number.length === 0 ? " border-red-600" : "")
                                }
                                onChange={e => updateForm("client", "representative_phone_number", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="mb-8 justify-between flex flex-row">
                        <ColoredTextInput
                            required={true}
                            type={"password"}
                            label={"Password"}
                            className={(errors && state.client.representative_password.length === 0) ? "border-red-600" : ""}
                            value={state.client.representative_password || ""}
                            onChange={e => updateForm("client", "representative_password", e.target.value)}
                        />
                        <Button text={"Generate Password"} size={"xs"} className={"ml-4"}
                                onClick={e => {
                                    e.preventDefault()
                                    updateForm("client", "representative_password", generatePasswords())
                                }}
                                bg={"bg-black"} color={"text-white"}
                        />
                    </div>
                    <div className="mb-8">
                        <ColoredTextInput
                            required={true}
                            label={"Project Name"}
                            className={(errors && state.project.project_name.length === 0) ? "border-red-600" : ""}
                            value={state.project.project_name || ""}
                            onChange={e => updateForm("project", "project_name", e.target.value)}
                        />
                    </div>

                    <ButtonGroup>
                        <Button
                            bg={"bg-red-600"}
                            color={"text-white"}
                            text={"Close"}
                            onClick={(e) => {
                                e.preventDefault()
                                closeModal()
                            }}
                        />
                        <Button
                            bg={"bg-yellow-500"}
                            color={"text-white"}
                            text={"Existing Client"}
                            onClick={e => {
                                e.preventDefault()
                                setIsOpen(false)
                                setIsProjectOpen(true)
                            }}
                        />
                        <Button
                            bg={"bg-blue-600"}
                            color={"text-white"}
                            text={"Save"}
                            onClick={e => processNewProject(e)}
                        />
                    </ButtonGroup>
                </form>
            </Modal>
        </div>
    );
}