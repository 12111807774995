import {
    adminFetchProject,
    editProjectState,
    fetchTalentPoolDraft,
    logContenders
} from "../../../../store/actions/adminActions";
import {useEffect, useState} from "react";
import {Title} from "../../../../components/misc";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ColoredSelect} from "../../../../components/forms";
import {toast} from "react-toastify";
import {noProfile} from "../../../../assets";
import {Button, ButtonGroup} from "../../../../components/buttons";
import {errorHandling} from "../../../../store/actions/actionHelpers";
import {updateForm} from "../../../../store/reducers/formsReducer";
import {ArrowPathIcon,} from "@heroicons/react/24/outline";
import {CheckCircleIcon, UserPlusIcon} from "@heroicons/react/24/solid";
import Modal from "react-modal";
import {ChevronDoubleLeftIcon, ChevronDoubleRightIcon} from "@heroicons/react/20/solid";

const customStyles = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "1200px",
        height: "85vh"
    },
};

const StageTwo = () => {
    const {project_id} = useParams()
    const dispatch = useDispatch()
    const [jobIdx, setJobIdx] = useState(0)
    const [jobData, setJobData] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [selectedContenders, setSelectedContenders] = useState([])

    const state = useSelector(state => state)

    const editState = () => {
        toast("Rolling back to requirements")

        editProjectState(parseInt(project_id.substring(8)), 0)
            .then(
                result => {
                    adminFetchProject(parseInt(project_id.substring(8)))
                        .then(result => {
                            errorHandling(result.data)

                            switch (result.data.http_code) {
                                case 201:
                                case 200:
                                    dispatch(updateForm({
                                        form: "project",
                                        value: result.data.data
                                    }))
                                    break
                            }
                        })
                }
            )
    }

    useEffect(() => {
        generateContendersList()
    }, [jobIdx])

    const approveContenders = () => {
        if (window.confirm(`There are a total of ${selectedContenders.length} for Job ${jobIdx + 1}, are you ready to proceed?`)) {
            setJobData({
                ...jobData,
                [jobIdx]: selectedContenders
            })
            setSelectedContenders([])
            if ((jobIdx + 1) < Object.keys(jobData).length)
                setJobIdx(jobIdx + 1)
        }
    }

    useEffect(() => {
        let res = {}
        JSON.parse(state.forms.project.details).requirements.map((item, key) => {
            res[key] = []
        })

        setJobData(res)
    }, [])

    const handleSubmit = () => {
        if (window.confirm(`There are \n${Object.keys(jobData).map(item => "    " + jobData[item].length + " contenders for Job " + (parseInt(item) + 1) + "\n").join("")} Would you like to proceed?`)) {
            toast("Updating contenders list")

            logContenders(
                parseInt(project_id.substring(8)),
                Object.keys(jobData).map(item => {
                    let res = []
                    jobData[item].forEach(contender => {
                        res.push({
                            user_id: contender.user_id,
                            job_id: item
                        })
                    })

                    return res
                }).flat()
            ).then(result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 201:
                        case 200:
                            toast.success("Contenders successfully logged")
                            break
                    }
                }
            ).finally(() => {
                adminFetchProject(parseInt(project_id.substring(8)))
                    .then(result => {
                        errorHandling(result.data)

                        switch (result.data.http_code) {
                            case 201:
                            case 200:
                                dispatch(updateForm({
                                    form: "project",
                                    value: result.data.data
                                }))
                                break
                        }
                    })
            })
        }
    }

    const mountContender = (con) => {
        dispatch(updateForm({
            form: "user",
            value: con
        }))
        setIsOpen(true)
    }

    const generateContendersList = () => {
        toast("Generating pool for Job " + (jobIdx + 1))
        fetchTalentPoolDraft(parseInt(project_id.substring(8)), {
            strictness: 0.5,
            job: jobIdx
        })
            .then((result) => {
                dispatch(updateForm({
                    form: "pool",
                    value: result.data.data
                }))
            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel="Contender Data"
            >
                {
                    Object.keys(state.forms.user).length === 0
                        ? null :
                        <>
                            <div className="flex flex-row w-fit mx-auto mb-4 ">
                                <div
                                    style={{
                                        background: `url(${state.forms.user.avatar}) top/cover no-repeat`
                                    }}
                                    className="h-24 rounded-full bg-gray-400 w-24"/>
                                <div className="flex flex-col justify-center px-4 text-sm">
                                    <h4 className={"font-medium text-capitalize text-base"}>{state.forms.user.first_name + " " + state.forms.user.last_name}</h4>
                                    <h4>{state.forms.user.email}</h4>
                                    <h4>{state.forms.user.country_code + (state.forms.user.phone_number ? (state.forms.user.phone_number[0] === "0" ? state.forms.user.phone_number.substring(1) : state.forms.user.phone_number) : "")}</h4>
                                    <div className={"flex flex-row flex-wrap justify-center py-1"}>
                                        {
                                            state.forms.user.skills.map((item, key) =>
                                                <div
                                                    className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                    key={key}>
                                                    <span className="mr-1">{item.skill_name}</span>
                                                    <span className="mr-1">-</span>
                                                    <span className="">{item.years}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"h-full"}>
                                <object data={state.forms.user.resume} type="application/pdf" width="100%"
                                        height="100%">
                                    <p>Oops looks like something went wrong. Download this resume? <a
                                        href={state.forms.user.resume}>Click Here</a></p>
                                </object>
                            </div>
                        </>
                }
            </Modal>

            <div className="w-full mb-4 flex flex-row items-center justify-between">
                <Title className={""}>Talent Pool Draft</Title>

                <div className="flex flex-row items-center">
                    <Button onClick={editState} text={"Edit Requirements"} bg={"bg-black"} color={"text-white"}
                            size={"md"} className={"mx-auto mr-2 block"}/>
                    <ColoredSelect label={"Job"}
                                   value={jobIdx.toString()}
                                   className={"w-full py-2 mr-2"}
                                   onChange={e => setJobIdx(e.target.value)}
                    >
                        {
                            JSON.parse(state.forms.project.details).requirements
                                .map((item, key) =>
                                    <option
                                        key={key}
                                        value={key.toString()}
                                    >
                                        {"Job " + (parseInt(key) + 1) + ": " + item.job_title}
                                    </option>
                                )
                        }
                    </ColoredSelect>
                    <button className={"ml-4 hover:animate-spin"} onClick={generateContendersList}>
                        <ArrowPathIcon className={"w-8 h-8"}/>
                    </button>
                </div>
            </div>

            <div className="w-full overflow-x-hidden grid grid-cols-7 gap-2 relative">
                {
                    state.forms.pool.length === 0
                        ?
                        <div className="col-span-3 flex-flex-col justify-center ">
                            <img src={noProfile.img} alt={noProfile.alt} className="mx-auto w-28 py-4"/>
                            <h4 className="text-center mb-2">
                                There are no talent profiles that match the requirements
                            </h4>
                            <Button onClick={editState} text={"Edit Requirements"} bg={"bg-black"} color={"text-white"}
                                    size={"md"} className={"mx-auto block"}/>

                        </div>
                        :
                        <div className={"col-span-3"}>
                            {
                                state.forms.pool.map((item, key) =>
                                    selectedContenders.findIndex(cont => cont.user_id === item.user_id) > -1 ? <></> :
                                        <TalentCard key={key} user={item} idx={key} mountContender={mountContender}
                                                    selectContender={(user) => {
                                                        if (selectedContenders.findIndex(i => i.user_id === user.user_id) === -1)
                                                            setSelectedContenders([...selectedContenders, user])
                                                    }}
                                        />
                                )
                            }
                        </div>
                }
                <div className="mt-24 flex flex-col items-center">
                    <Button color={"text-white"} bg={"bg-red-600"} size={"md"} className={"mb-4"}>
                        <ChevronDoubleRightIcon onClick={() => setSelectedContenders(state.forms.pool)}
                                                className={"w-8 h-8 mx-auto"}/>
                    </Button>
                    <Button color={"text-white"} bg={"bg-red-600"} size={"md"} className={"mb-4"}>
                        <ChevronDoubleLeftIcon onClick={() => setSelectedContenders([])} className={"w-8 h-8 mx-auto"}/>
                    </Button>
                </div>

                {
                    selectedContenders.length === 0
                        ?
                        <div className="col-span-3 flex-flex-col justify-center ">
                            <img src={noProfile.img} alt={noProfile.alt} className="mx-auto w-28 py-4"/>
                            <h4 className="text-center mb-2">
                                You have not approved any talent profiles
                            </h4>
                        </div>
                        :
                        <div className={"col-span-3"}>
                            {
                                selectedContenders.map((item, key) =>
                                    <TalentCard key={key} user={item} idx={key} mountContender={mountContender}
                                                selected={true}
                                                removeContender={(user) => setSelectedContenders(selectedContenders.filter(i => i.user_id !== user.user_id))}
                                    />
                                )
                            }
                        </div>
                }
            </div>

            <div className="fixed bottom-8 right-0">
                {
                    new Set(Object.keys(jobData).map(item => jobData[item].length === 0)).has(true)
                        ?
                        <button>
                            <UserPlusIcon
                                onClick={approveContenders}
                                className={"bg-red-600 text-white rounded-full w-12 h-12 p-2"}/>
                        </button>
                        :
                        <button>
                            <CheckCircleIcon
                                onClick={handleSubmit}
                                className={"bg-red-600 text-white rounded-full w-12 h-12 p-2"}/>
                        </button>
                }
            </div>
        </>
    )
}

const TalentCard = ({user, selected, mountContender, selectContender, removeContender}) => {
    const dispatch = useDispatch()

    return (
        <div
            className={"border border-gray-400 w-full rounded-lg mb-2 py-2 px-4 flex flex-row justify-start"}>
            <div className="h-24 rounded-full bg-gray-400 w-24 flex justify-center items-center">
                <img src={user.avatar} alt={user.first_name + " " + user.last_name}
                     className="mx-auto rounded-full w-24"/>
            </div>

            <div className="py-1 pl-6    pr-2">
                <h4 className={"capitalize font-bold"}>{user.first_name + " " + user.last_name}</h4>
                <div className={"flex flex-row flex-wrap py-1"}>
                    {
                        user.skills.map((item, key) =>
                            <div
                                className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                key={key}>
                                <span className="mr-1">{item.skill_name}</span>
                                <span className="mr-1">-</span>
                                <span className="">{item.years}</span>
                            </div>
                        )
                    }
                </div>
                <div className="py-1 w-full flex flex-row justify-center">
                    <ButtonGroup className={""}>
                        {
                            selected ?
                                <Button
                                    onClick={() => removeContender(user)}
                                    text={"Remove Contender"} size={"xs"} bg={"bg-red-600"} color={"text-white"}/>
                                :
                                <Button
                                    onClick={() => selectContender(user)}
                                    text={"Progress Contender"} size={"xs"} bg={"bg-green-600"} color={"text-white"}/>
                        }
                        <Button text={"View Data"} size={"xs"} bg={"bg-blue-600"} color={"text-white"}
                                onClick={() => mountContender(user)}/>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    )
}

export default StageTwo