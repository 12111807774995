import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {terminateSession} from "../store/reducers/authReducer";
import {invalidateToken} from "../store/actions/authenticatedActions";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const state = useSelector(state => state)
    const [refresh, setRefresh] = useState(true)

    useEffect(() => {
        if (state.auth.auth.authenticated)
            return

        toast.success("You've been logged out.")
        navigate("/login")
    }, [refresh]);


    useEffect(() => {
        invalidateToken()
            .finally(() => {
                    localStorage.removeItem("access_token")
                    localStorage.removeItem("user_data")
                    dispatch(terminateSession)
                    setRefresh(true)
                }
            )
    }, [])

    useEffect(() => {
        setTimeout(() => {
            navigate(0)
        }, 3500)
    }, [])

    return (
        <div className={"container mx-auto my-24"}>
            If page doesn't change in 5 seconds, refresh...
        </div>
    )
}

export default Logout