import {useEffect, useState} from "react";
import {uploadFile} from "../../../store/actions/authenticatedActions";
import {fetchAllSkills} from "../../../store/actions/adminActions";
import {errorHandling} from "../../../store/actions/actionHelpers";
import {createTalentForJob, fetchProjectRequirements} from "../../../store/actions/publicActions";
import {useNavigate, useParams} from "react-router-dom";
import {ColoredTextInput} from "../../../components/forms";
import {Button} from "../../../components/buttons";
import {toast} from "react-toastify";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import {XMarkIcon} from "@heroicons/react/24/solid";
import {NotFound} from "../notFound";

const JobsForm = () => {
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        profile_picture: "",
        resume: "",
        skills: [],
    })
    const [skill, setSkill] = useState("")
    const [skills, setSkills] = useState("")
    const [errors, setErrors] = useState(false)
    const {project_id, job_id} = useParams()
    const [jd, setJD] = useState("")
    const navigate = useNavigate()
    const [mount, setMount] = useState(false)
    const [notFound, setNotFound] = useState(false)

    useEffect(() => {
        fetchAllSkills()
            .then(
                result => {
                    errorHandling(result.data)
                    setMount(true)
                    switch (result.data.http_code) {
                        case 201:
                        case 200:
                            setSkills(result.data.data)
                            break
                    }
                }
            )

        fetchProjectRequirements(parseInt(project_id.substring(8)))
            .then(result => {
                errorHandling(result.data)

                switch (result.data.http_code) {
                    case 201:
                    case 200:
                        if (result.data.data.length === 0)
                            setNotFound(true)
                        else {
                            let stringifyData = JSON.parse(result.data.data.details).requirements[parseInt(job_id) - 1]
                            if (stringifyData)
                                setJD(stringifyData)
                            else
                                setNotFound(true)
                        }

                        break
                    default:
                        setNotFound(true)
                        break;
                }
            })
    }, [])

    useEffect(() => {
        if (mount) {
            const input = document.querySelector("#phone");
            intlTelInput(input, {
                customContainer: "w-full",
                initialCountry: "NG",
                preferredCountries: ["NG", "GH", "US", "GB"]
            });
        }
    }, [mount])

    const updateForm = (n, v) => {
        setFormData({...formData, [n]: v})
    }

    const addToSkills = (i) => {
        if (formData.skills.filter(item => item.name === i.name).length === 0)
            setFormData({...formData, skills: [...formData.skills, {...i, years: 1}]})

        setSkill("")
    }

    const uploadCandidateFiles = (e, n) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append("logo", e.target.files[0])

        uploadFile(formData)
            .then(res => {
                updateForm(n, res.data.data.filename)
            })
    }

    const submitForm = (e) => {
        e.preventDefault()

        let cc = document.querySelector("#phone").parentElement.childNodes[0].childNodes[0]
        if (new Set(Object.keys(formData).map(item => formData[item].length > 0)).has(false)) {
            setErrors(true)
            return
        }

        createTalentForJob(parseInt(project_id.substring(8)), job_id, ({
            ...formData,
            country_code: cc.title.substring(cc.title.indexOf(":") + 2)
        }))
            .then(result => {
                switch (result.data.http_code) {
                    case 200:
                    case 201:
                        toast.success("Your data has been submitted!")
                        navigate("/")
                        break
                    default:
                        toast.error(result.data.error || result.data.message)
                        toast.error("Please try again")
                        break;
                }
            })

        setMount(true)
    }

    return (
        notFound ?
            <NotFound/>
            :
            <div className={"container mx-auto my-12 grid grid-cols-1 lg:grid-cols-2 gap-0 w-full"}>
                <div
                    style={{height: "756px"}}
                    className="hidden lg:block  bg-red-600 rounded-l-lg text-sm font-light px-4 py-6 h-[80vh] overflow-y-scroll scrollbar-thin scrollbar-thumb-black scrollbar-track-slate-300">
                    {
                        jd ?
                            <div dangerouslySetInnerHTML={{"__html": jd.job_description}}/>
                            : null
                    }
                </div>
                <form
                    className="job-form-height mx-auto py-8 px-4 rounded-lg lg:rounded-l-none border w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="mb-8">
                            <ColoredTextInput
                                type={"file"}
                                required={true}
                                className={errors && formData.profile_picture.length === 0 ? "border-red-600" : ""}
                                onChange={e => uploadCandidateFiles(e, "profile_picture")}
                                label={"Profile Picture"}
                            />
                        </div>

                        <div className="mb-8">
                            <ColoredTextInput
                                type={"file"}
                                required={true}
                                className={errors && formData.resume.length === 0 ? "border-red-600" : ""}
                                onChange={e => uploadCandidateFiles(e, "resume")}
                                label={"Resume/CV"}
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="mb-8">
                            <ColoredTextInput
                                value={formData.first_name || ""}
                                className={errors && formData.first_name.length === 0 ? "border-red-600" : ""}
                                onChange={e => updateForm("first_name", e.target.value)}
                                label={"First Name"}
                            />
                        </div>

                        <div className="mb-8">
                            <ColoredTextInput
                                value={formData.last_name || ""}
                                className={errors && formData.last_name.length === 0 ? "border-red-600" : ""}
                                onChange={e => updateForm("last_name", e.target.value)}
                                label={"Last Name"}
                            />
                        </div>
                    </div>

                    <div className="mb-8">
                        <ColoredTextInput
                            value={formData.email || ""}
                            className={errors && formData.email.length === 0 ? "border-red-600" : ""}
                            onChange={e => updateForm("email", e.target.value)}
                            label={"Email"}
                        />
                    </div>

                    <div className="mb-6">
                        <input
                            name={"phone"}
                            id={"phone"}
                            value={formData.phone_number || ""}
                            className={
                                "w-full transition-all duration-300 w-full outline-none px-4 py-3 rounded border " +
                                (errors && formData.phone_number.length === 0 ? " border-red-600" : "")
                            }
                            onChange={e => updateForm("phone_number", e.target.value)}
                        />
                    </div>

                    <div className="mb-8">
                        <h4 className={"px-4 pb-2 text-sm"}>Skills</h4>
                        <div
                            className={"mb-4 px-2 w-full drop-shadow-lg overflow-x-hidden overflow-y-scroll scrollbar-thin scrollbar-thumb-black scrollbar-track-slate-300 border rounded py-2"}
                            style={{height: "182px"}}>
                            {
                                formData.skills.length === 0 ?
                                    <span
                                        className={"bg-red-600 py-1 px-2 text-white rounded"}>
                    Add your skills
                    </span>
                                    :
                                    formData.skills.map((skill_1, key) =>
                                        <div data-aos="fade-in" className={"grid grid-cols-5 gap-1 mb-2"} key={key}>
                                            <div className="col-span-3 flex flex-col justify-center">
                                                {skill_1.name}
                                            </div>
                                            <div className="flex flex-col items-center justify-center">
                                                <input
                                                    type={"number"}
                                                    value={skill_1.years}
                                                    onChange={e =>
                                                        setFormData({
                                                            ...formData,
                                                            skills: [...formData.skills.filter(s => s.name != skill_1.name), {
                                                                ...skill_1,
                                                                years: e.target.value
                                                            }]
                                                        })
                                                    }
                                                    style={{paddingTop: "6px", paddingBottom: "6px"}}
                                                    className={"w-full transition-all text-xs w-full outline-none px-2 text-right rounded border"}/>
                                            </div>
                                            <div className={"flex flex-col items-center justify-center"}>
                                                <XMarkIcon
                                                    onClick={() => setFormData({
                                                        ...formData,
                                                        skills: formData.skills.filter(i => i.skill_id !== skill_1.skill_id)
                                                    })}
                                                    className={"w-5 h-5 cursor-pointer transition duration-300 hover:text-red-600"}
                                                />
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                        <div className="relative">
                            <ColoredTextInput
                                className={errors && formData.skills.length === 0 ? "border-red-600" : ""}
                                value={skill}
                                onChange={e => setSkill(e.target.value)}
                            />

                            {
                                skill.length === 0 ?
                                    <></>
                                    :
                                    <div
                                        data-aos="fade-in"
                                        className="absolute z-20 border rounded-lg mt-2 shadow-lg  bg-white w-full flex flex-row py-4 px-2 flex-wrap">
                                        {
                                            skills.filter(item => item.name.includes(skill)).length === 0 ?
                                                <h4 className={"text-center mx-auto"}>
                                                    Skill missing? Check name or contact us
                                                </h4>
                                                :
                                                skills.filter(item => item.name.includes(skill)).map((i, k) =>
                                                    <div
                                                        data-aos="slide-left"
                                                        data-aos-delay={(50 * (k + 1)).toString()}
                                                        onClick={() => addToSkills(i)}
                                                        className={"p-2 cursor-pointer mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                        key={k}>{i.name}
                                                    </div>
                                                )
                                        }
                                    </div>
                            }
                        </div>
                    </div>

                    <Button
                        color={"text-white"}
                        bg={"bg-black"}
                        text={"Submit"}
                        onClick={e => submitForm(e)}/>
                </form>
            </div>
    )
}

export default JobsForm