import {HorizontalTrackComponent} from "../../../components/track";
import {logoLarge, spinner} from "../../../assets";
import {formatPrice} from "../../../utils/helpers";
import {Button} from "../../../components/buttons";
import {useState} from "react";
import {useSelector} from "react-redux";

export const StageTwo = (props) => {
    return(
        <>
            <div className={"flex flex-col items-center justify-center items-center h-96"}>
                <img src={logoLarge.img} alt={logoLarge.alt} className={"w-32"}/>
                <div className="text-center">
                    Waiting for Talent Pool Draft Approval
                </div>
            </div>
        </>
    )
}