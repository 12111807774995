import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
    ArchiveBoxIcon,
    ArrowLeftOnRectangleIcon,
    PresentationChartBarIcon,
    RectangleGroupIcon,
    UserIcon
} from "@heroicons/react/24/outline";
import {
    admin_content_url,
    admin_dashboard_url,
    admin_projects_url,
    admin_users_url,
    logout_url
} from "../../utils/strings";
import {useState} from "react";

export const AdminSidebar = () => {
    const [active, setActive] = useState(false)
    const state = useSelector(state => state)

    return (
        <>
            <nav
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
                className={"z-40 transition duration-300 bg-gray-50 flex justify-center items-center border-r h-full top-0 left-0 absolute w-[50px] hover:w-[220px]"}
                style={{transitionProperty: "width"}}
            >
                <div
                    className={"divide-y transition duration-300 mx-auto w-full flex flex-col items-center justify-start h-full overflow-hidden w-[220px]"}>
                    <div className="transition duration-300 w-full">
                        <Link to={admin_dashboard_url}
                              className={"justify-start flex flex-row block mt-2 mb-2 items-center text-gray-600 py-4 px-2 w-full hover:bg-red-600 hover:text-white transition duration-300 " + (state.publicSettings.location === "dashboard" ? " bg-red-600 shadow-2xl shadow-inner text-white" : "")}
                              style={{width: "300px"}}>
                            <RectangleGroupIcon className={"h-6 w-8 mr-5"}/>
                            {active ?
                                <span className="capitalize text-xs pl-2">dashboard</span>
                                :
                                null
                            }
                        </Link>
                    </div>
                    <div className="transition duration-300 w-full pt-4">
                        <Link to={admin_projects_url}
                              className={"justify-start flex flex-row block mb-2 items-center text-gray-600 py-4 px-2 w-full hover:bg-red-600 hover:text-white transition duration-300 " + (state.publicSettings.location === "projects" ? " bg-red-600 shadow-2xl shadow-inner text-white" : "")}
                              style={{width: "300px"}}>
                            <PresentationChartBarIcon className={"h-6 w-8 mr-5"}/>
                            {active ?
                                <span className="capitalize text-xs pl-2">projects</span>
                                :
                                null
                            }
                        </Link>
                        <Link to={admin_content_url}
                              className={"justify-start flex flex-row block mb-2 items-center text-gray-600 py-4 px-2 w-full hover:bg-red-600 hover:text-white transition duration-300 " + (state.publicSettings.location === "content" ? " bg-red-600 shadow-2xl shadow-inner text-white" : "")}
                              style={{width: "300px"}}
                        >
                            <ArchiveBoxIcon className={"h-6 w-8 mr-5"}/>
                            <span className="text-nowrap capitalize text-xs">content Management</span>
                        </Link>
                        {
                            (state.auth.user_data && state.auth.user_data.user_type_id === 1) ?
                                <Link to={admin_users_url}
                                      className={"justify-start flex flex-row block mb-2 items-center text-gray-600 py-4 px-2 w-full hover:bg-red-600 hover:text-white transition duration-300 " + (state.publicSettings.location === "users" ? " bg-red-600 shadow-2xl shadow-inner text-white" : "")}
                                      style={{width: "300px"}}>
                                    <UserIcon className={"h-6 w-8 mr-5"}/>
                                    {active ?
                                        <span className="capitalize text-xs pl-2">users Management</span>
                                        :
                                        null
                                    }
                                </Link> : <></>
                        }
                    </div>
                    <div className="transition duration-300 w-full py-2 mt-auto">
                        <Link to={logout_url}
                              className={"transition duration-300 justify-start flex flex-row block mb-2 items-center text-gray-600 py-4 px-2 w-full hover:bg-red-600 hover:text-white transition duration-300"}
                              style={{width: "300px"}}>
                            <ArrowLeftOnRectangleIcon className={"h-6 w-8 mr-5"}/>
                            {active ?
                                <span className="capitalize text-xs pl-2">Sign Out</span>
                                :
                                null
                            }
                        </Link>
                    </div>
                </div>
            </nav>
        </>
    )
}