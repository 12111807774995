import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

export function CheckIfLoggedIn() {
    const state = useSelector(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        if (state.auth.auth.authenticated)
            return

        toast.error("You need to be signed in to access this page.")
        navigate("/login")
    }, [state]);

    return (
        <>
        </>
    );
}

export function CheckIfLoggedOut() {
    const state = useSelector(state => state)
    const navigate = useNavigate()

    useEffect(() => {
        if (!state.auth.auth.authenticated)
            return

        switch (state.auth.user_data.user_type_id) {
            case 1:
                document.location.pathname = "/admin/dashboard"
                break
            case 2:
                document.location.pathname = "/admin/dashboard"
                break
            case 3:
                document.location.pathname = "/client/dashboard"
                break
            case 4:
                document.location.pathname = "/talent/dashboard"
                break
            default:
                document.location.pathname = "/"
                break
        }
    }, [state]);

    return (
        <>
        </>
    );
}