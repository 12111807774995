import {Outlet} from "react-router-dom";
import {ClientHeader} from "../../components/navs/clientHeader";
import {CheckIfLoggedOut} from "../../utils/checkIfLoggedIn";
import {ClientFooter} from "../../components/navs/clientFooter";

export const ClientLayout = () => {
    return (
        <>
            <ClientHeader/>
            {/*<div className="grid grid-cols-[74px_1fr]">*/}
            {/*    <div className="relative h-full">*/}
            {/*        <ClientSidebar/>*/}
            {/*    </div>*/}
                <div className="p-2 w-full min-h-screen">
                    <Outlet/>
                </div>
            {/*</div>*/}
            <ClientFooter/>
        </>
    )
}