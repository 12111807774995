import {admin_projects_url} from "../../../utils/strings";
import {addLeadingZeros, parseDateToId, parseProjectState} from "../../../utils/helpers";
import {Link} from "react-router-dom";

export const ProjectViewCard = ({item}) => {
    return (
        <Link className={"block"}
              to={admin_projects_url + parseDateToId(item.created_at) + addLeadingZeros(item.project_id)}
              className="w-full border rounded-lg py-2 px-1 cursor-pointer flex flex-row mb-3">
            <div className="h-20 w-20  flex flex-col justify-center">
                <img src={item.company_logo} alt={item.company_name}
                     className={"w-20  4 mx-auto"}/>
            </div>
            <div className="pl-2 pr-1 flex flex-col justify-center">
                <h4 className={"font-bold text-sm"}>{item.company_name} - {item.name}</h4>
                <h4 className={"font-light text-xs"}>{parseProjectState(item.state)}</h4>
            </div>
        </Link>
    )
}