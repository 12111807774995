import {atosLogo, imgPlcLogo, inqIncLogo, mtnLogo, royalExchangeLogo, taasPreview, vfdLogo} from "../../assets";
import {Link} from "react-router-dom";

const Home = () => {

    return (
        <>
            <section className="w-full grid grid-cols-1 md:grid-cols-2 h-screen__minus-header container mx-auto">
                <div className="flex flex-col justify-center px-4 lg:pr-12 landing-background">
                    <h4 className="capitalize leading-8 lg:leading-tight text-2xl md:text-5xl font-bold mb-6">
                        highly talented <br className={"lg:hidden"}/>personnel on demand
                    </h4>
                    <p className={"mb-8 lg:mb-10 leading-6 lg:leading-8"}>
                        Outsource your hiring process to a qualified team of recruiters with access to a robust pool of
                        vetted talent
                    </p>

                    <div className="flex flex-row mb-8 lg:mb-12">
                        <Link
                            className={"text-sm md:text-base bg-red-600 text-white w-fit px-4 py-3 rounded-xl mr-4"}
                            to={"/contact-us"}>Hire a Team</Link>

                        <Link
                            className={"text-sm md:text-base border-red-600 border-r-2 border-l-2  text-red-600 w-fit px-4 py-3 rounded-xl"}
                            to={"/contact-us"}>Request a Demo</Link>
                    </div>

                    <div className="">
                        <h4 className="text-xs text-gray-600 mb-6  lg:mb-6">
                            Working with the best
                        </h4>

                        <div className="flex flex-row items-center">
                            <img className={"h-4 mr-4"} src={atosLogo.img} alt={atosLogo.alt}/>
                            <img className={"h-6 mr-4"} src={mtnLogo.img} alt={mtnLogo.alt}/>
                            <img className={"h-6 mr-4"} src={vfdLogo.img} alt={vfdLogo.alt}/>
                            <img className={"h-6 mr-4"} src={royalExchangeLogo.img} alt={royalExchangeLogo.alt}/>
                            <img className={"h-12 mr-4"} src={imgPlcLogo.img} alt={imgPlcLogo.alt}/>
                            <img className={"h-6 mr-4"} src={inqIncLogo.img} alt={inqIncLogo.alt}/>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex">
                    <img src={taasPreview.img} alt={taasPreview.alt}  className={"absolute top-40 border shadow-lg rounded-lg"} style={{width: "1200px"}}/>
                </div>
            </section>
        </>
    )
}

export default Home