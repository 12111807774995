import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    screenWidth: screen.width || 0,
    location: "home"
}

const publicSlice = createSlice({
    name: "publicSettings",
    initialState: initialState,
    reducers: {
        updatePublicSetting: (state, action) => {
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        }
    }
})

export const {updatePublicSetting} = publicSlice.actions
export default publicSlice.reducer