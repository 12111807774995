import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Modal from "react-modal";
import {toast} from "react-toastify";
import {ArrowPathIcon, ClockIcon} from "@heroicons/react/24/outline";
import {
    adminCreateInterviewSingle,
    clientChangeContenderState,
    clientDisqualifyContender,
    clientFetchContenders,
    createInterviewBulk
} from "../../../../store/actions/clientActions";
import {errorHandling} from "../../../../store/actions/actionHelpers";
import {updateForm} from "../../../../store/reducers/formsReducer";
import {Button, ButtonGroup} from "../../../../components/buttons";
import {ColoredSelect} from "../../../../components/forms";
import {HorizontalTrackComponent} from "../../../../components/track";
import {spinner} from "../../../../assets";
import {formatPrice} from "../../../../utils/helpers";
import {EyeIcon} from "@heroicons/react/20/solid";
import {Title} from "../../../../components/misc";
import {adminFetchProject, advanceProjectToStageFour} from "../../../../store/actions/adminActions";

const customStyles = {
    content: {
        top: 'calc(50% + 35px)',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "1200px",
        height: "85vh"
    },
};

const customStyles_2 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "800px",
    },
};

const customStyles_3 = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "94%",
        maxWidth: "800px",
        height: "70vh"
    },
};

const StageThree = (props) => {
    const [jobIdx, setJobIdx] = useState(-1)
    const state = useSelector(state => state)
    const [isOpen, setIsOpen] = useState(false)
    const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)
    const [isContenderDataOpen, setIsContenderDataOpen] = useState(false)
    const [interviewScheduler, setInterviewScheduler] = useState(0)
    const {project_id} = useParams()
    const dispatch = useDispatch()
    const [schedulerFormData, setSchedulerFormData] = useState(JSON.parse(state.auth.user_data.company_availability) || {
        time_in: "00",
        time_out: "00",
        available_days: {
            sundays: false,
            mondays: false,
            tuesdays: false,
            wednesdays: false,
            thursdays: false,
            fridays: false,
            saturdays: false
        },
        default_availability: false
    })
    const [selectedContenders, setSelectedContenders] = useState([])

    useEffect(() => {
        init()
        setJobIdx(-1)
    }, [project_id])

    useEffect(() => {
        setIsOpen(false)
        setIsContenderDataOpen(false)
        setInterviewScheduler(0)
    }, [jobIdx])

    const init = () => {
        if (state.forms.pool.length !== 0) {
            toast.warning("Fetching contenders...")
        }

        clientFetchContenders(parseInt(project_id.substring(8)))
            .then(result => {
                errorHandling(result.data)
                switch (result.data.http_code) {
                    case 200:
                    case 201:
                        dispatch(updateForm({
                            form: "pool",
                            value: result.data.data
                        }))
                        break
                }
            })
    }

    const scheduleSingle = (con) => {
        dispatch(updateForm({
            form: "user",
            value: con
        }))
        setInterviewScheduler(1)
    }

    const scheduleBulk = () => {
        setInterviewScheduler(2)
    }

    const mountContender = (con) => {
        // console.log(con)
        dispatch(updateForm({
            form: "user",
            value: con
        }))
        setIsContenderDataOpen(true)
    }

    const scheduleInterviewSingle = () => {
        if (parseInt(schedulerFormData.time_in) >= parseInt(schedulerFormData.time_out))
            toast.error("Time out has to be at least an hour later than time in")
        else if (!(new Set(Object.keys(schedulerFormData.available_days).map(i => schedulerFormData.available_days[i])).has(true)))
            toast.error("You have to pick least one day to be available for interviews")
        else {
            adminCreateInterviewSingle(state.forms.project.client_id, parseInt(project_id.substring(8)), state.forms.user.project_talent_id, {
                ...schedulerFormData,
                job_title: JSON.parse(state.forms.project.details).requirements[jobIdx].job_title
            })
                .then(result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 200:
                        case 201:
                            toast.success(result.data.message)
                            // setInterviewScheduler(0)
                            break;
                    }
                })
        }
    }

    const scheduleInterviewBulk = () => {
        if (parseInt(schedulerFormData.time_in) >= parseInt(schedulerFormData.time_out)) {
            toast.error("Time out has to be at least an hour later than time in")
            return;
        } else if (!(new Set(Object.keys(schedulerFormData.available_days).map(i => schedulerFormData.available_days[i])).has(true))) {
            toast.error("You have to pick least one day to be available for interviews")
            return;
        } else {
            createInterviewBulk(parseInt(project_id.substring(8)), {
                ...schedulerFormData,
                contenders: state.forms.pool.map(i => i.project_talent_id)
            })
                .then(result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 200:
                        case 201:
                            toast.success(result.data.message)
                            setInterviewScheduler(0)
                            break;
                    }
                })
        }
    }

    const proceedToStageFour = () => {
        advanceProjectToStageFour(parseInt(project_id.substring(8)), selectedContenders.map(i => i.project_talent_id))
            .then(() => {
                adminFetchProject(parseInt(project_id.substring(8)))
                    .then(result => {
                        errorHandling(result.data)

                        switch (result.data.http_code) {
                            case 201:
                            case 200:
                                dispatch(updateForm({
                                    form: "project",
                                    value: result.data.data
                                }))
                                break
                        }
                    })
            })
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                style={customStyles}
                contentLabel="JD"
            >
                <div className="px-4">
                    <div
                        style={{
                            maxHeight: "75vh",
                            overflowY: "scroll"
                        }}
                        className={""}
                        dangerouslySetInnerHTML={{
                            "__html": jobIdx === -1 ? "" :
                                JSON.parse(state.forms.project.details).requirements[jobIdx] ? JSON.parse(state.forms.project.details).requirements[jobIdx].job_description : ""
                        }}/>
                </div>
            </Modal>
            <Modal
                isOpen={isContenderDataOpen}
                onRequestClose={() => setIsContenderDataOpen(false)}
                style={customStyles}
                contentLabel="Contender Data"
            >
                {
                    Object.keys(state.forms.user).length === 0
                        ? null :
                        <>
                            <div className="flex flex-row w-fit mx-auto mb-4 ">
                                <div
                                    style={{
                                        background: `url(${state.forms.user.avatar}) top/cover no-repeat`
                                    }}
                                    className="h-24 rounded-full bg-gray-400 w-24"/>
                                <div className="flex flex-col justify-center px-4 text-sm">
                                    <h4 className={"font-medium text-capitalize text-base"}>{state.forms.user.first_name + " " + state.forms.user.last_name}</h4>
                                    <h4>{state.forms.user.email}</h4>
                                    <h4>{state.forms.user.country_code + (state.forms.user.phone_number ? (state.forms.user.phone_number[0] === "0" ? state.forms.user.phone_number.substring(1) : state.forms.user.phone_number) : "")}</h4>
                                    <div className={"flex flex-row flex-wrap justify-center py-1"}>
                                        {
                                            state.forms.user.skills.map((item, key) =>
                                                <div
                                                    className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                    key={key}>
                                                    <span className="mr-1">{item.skill_name}</span>
                                                    <span className="mr-1">-</span>
                                                    <span className="">{item.years}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"h-full"}>
                                <object data={state.forms.user.resume} type="application/pdf" width="100%"
                                        height="100%">
                                    <p>Oops looks like something went wrong. Download this resume? <a
                                        href={state.forms.user.resume}>Click Here</a></p>
                                </object>
                            </div>
                        </>
                }
            </Modal>
            <Modal
                isOpen={interviewScheduler !== 0}
                onRequestClose={() => setInterviewScheduler(0)}
                style={customStyles_2}
                contentLabel="Interview Scheduler"
            >
                {
                    interviewScheduler === 1 ?
                        <div className={""}>
                            <div className="flex flex-row border-b mb-2 pb-2 w-fit mx-auto mb-4 ">
                                <div
                                    style={{
                                        background: `url(${state.forms.user.avatar}) top/cover no-repeat`
                                    }}
                                    className="h-24 rounded-full bg-gray-400 w-24"/>
                                <div className="flex flex-col justify-center px-4 text-sm">
                                    <h4 className={"font-medium text-capitalize text-base"}>{state.forms.user.first_name + " " + state.forms.user.last_name}</h4>
                                    <h4>{state.forms.user.email}</h4>
                                    <h4>{state.forms.user.country_code + (state.forms.user.phone_number ? (state.forms.user.phone_number[0] === "0" ? state.forms.user.phone_number.substring(1) : state.forms.user.phone_number) : "")}</h4>
                                    <div className={"flex flex-row flex-wrap justify-center py-1"}>
                                        {
                                            state.forms.user.skills.map((item, key) =>
                                                <div
                                                    className={"p-1 text-xs font-light mr-2 mb-2 bg-gray-200 rounded w-fit"}
                                                    key={key}>
                                                    <span className="mr-1">{item.skill_name}</span>
                                                    <span className="mr-1">-</span>
                                                    <span className="">{item.years}</span>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div className="mb-4 pb-4 border-b pb-8">
                                    <h4 className="text-lg font-bold pt-2 pb-4">
                                        Set your availability
                                    </h4>

                                    <p>
                                        Give the candidate a time range for the interview
                                    </p>
                                </div>

                                <div className={"pt-4"}>
                                    <form className="">
                                        <div className="grid grid-cols-2 gap-4 mb-8">
                                            <ColoredSelect
                                                onChange={e => setSchedulerFormData({
                                                    ...schedulerFormData,
                                                    "time_in": e.target.value
                                                })}
                                                value={schedulerFormData.time_in}
                                                label={"Time In:"}>
                                                {
                                                    ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"].map((item, key) =>
                                                        <option
                                                            value={item}
                                                            key={key}
                                                        >
                                                            {item}:00
                                                        </option>
                                                    )
                                                }
                                            </ColoredSelect>

                                            <ColoredSelect
                                                onChange={e => setSchedulerFormData({
                                                    ...schedulerFormData,
                                                    "time_out": e.target.value
                                                })}
                                                value={schedulerFormData.time_out}
                                                label={"Time Out:"}>
                                                {
                                                    ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"].map((item, key) =>
                                                        <option
                                                            value={item}
                                                            key={key}
                                                        >
                                                            {item}:00
                                                        </option>
                                                    )
                                                }
                                            </ColoredSelect>
                                        </div>

                                        <div className="">
                                            <h4 className="font-bold mb-3">Available Days</h4>

                                            <div className="grid grid-cols-7 mb-3">
                                                {
                                                    ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"].map((day, k) =>
                                                        <div key={k}
                                                             className={"border py-6 " + (0 === k ? "rounded-l-xl" : 6 === k ? "rounded-r-xl" : "")}>
                                                            <input id={k.toString()} name={"available_days"}
                                                                   checked={schedulerFormData.available_days[day.toLowerCase()]}
                                                                   onChange={() => setSchedulerFormData({
                                                                       ...schedulerFormData,
                                                                       available_days: {
                                                                           ...schedulerFormData.available_days,
                                                                           [day.toLowerCase()]: !schedulerFormData.available_days[day.toLowerCase()]
                                                                       }
                                                                   })}
                                                                   className={"mx-auto block mb-3"} type="checkbox"/>
                                                            <label htmlFor={k.toString()}
                                                                   className={"capitalize text-center text-sm block"}>{day}</label>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className={"flex flex-row"}>
                                                <input id={"default_availability"} name={"default_availability"}
                                                       checked={schedulerFormData.default_availability}
                                                       onChange={() => setSchedulerFormData({
                                                           ...schedulerFormData,
                                                           ["default_availability"]: !schedulerFormData.default_availability
                                                       })}
                                                       className={"mx-auto mr-2"} type="checkbox"/>
                                                <label htmlFor={"default_availability"}
                                                       className={"capitalize text-center text-sm block"}>Make this time
                                                    range your company's default</label>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="flex flex-row justify-center items-center pt-8 pb-4 w-full">
                                        <div className="flex flex-row items-center w-fit mr-auto">
                                            <ClockIcon className={"w-6 h-6 mr-2"}/>
                                            <span>Don't worry, you can set this per candidate or as a company default</span>
                                        </div>

                                        <Button
                                            onClick={() => scheduleInterviewSingle()}
                                            text={"Continue"}
                                            size={"md"} bg={"bg-blue-600"} color={"text-white"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <div className="">
                                <div className="mb-4 pb-4 border-b pb-8">
                                    <h4 className="text-lg font-bold pt-2 pb-4">
                                        Set your availability
                                    </h4>

                                    <p>
                                        You are inviting all current candidates in your contenders list to schedule
                                        interviews.
                                    </p>
                                </div>

                                <div className={"pt-4"}>
                                    <form className="">
                                        <div className="grid grid-cols-2 gap-4 mb-8">
                                            <ColoredSelect
                                                onChange={e => setSchedulerFormData({
                                                    ...schedulerFormData,
                                                    "time_in": e.target.value
                                                })}
                                                value={schedulerFormData.time_in}
                                                label={"Time In:"}>
                                                {
                                                    ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"].map((item, key) =>
                                                        <option
                                                            value={item}
                                                            key={key}
                                                        >
                                                            {item}:00
                                                        </option>
                                                    )
                                                }
                                            </ColoredSelect>

                                            <ColoredSelect
                                                onChange={e => setSchedulerFormData({
                                                    ...schedulerFormData,
                                                    "time_out": e.target.value
                                                })}
                                                value={schedulerFormData.time_out}
                                                label={"Time Out:"}>
                                                {
                                                    ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"].map((item, key) =>
                                                        <option
                                                            value={item}
                                                            key={key}
                                                        >
                                                            {item}:00
                                                        </option>
                                                    )
                                                }
                                            </ColoredSelect>
                                        </div>

                                        <div className="">
                                            <h4 className="font-bold mb-3">Available Days</h4>

                                            <div className="grid grid-cols-7 mb-3">
                                                {
                                                    ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"].map((day, k) =>
                                                        <div key={k}
                                                             className={"border py-6 " + (0 === k ? "rounded-l-xl" : 6 === k ? "rounded-r-xl" : "")}>
                                                            <input id={k.toString()} name={"available_days"}
                                                                   checked={schedulerFormData.available_days[day.toLowerCase()]}
                                                                   onChange={() => setSchedulerFormData({
                                                                       ...schedulerFormData,
                                                                       available_days: {
                                                                           ...schedulerFormData.available_days,
                                                                           [day.toLowerCase()]: !schedulerFormData.available_days[day.toLowerCase()]
                                                                       }
                                                                   })}
                                                                   className={"mx-auto block mb-3"} type="checkbox"/>
                                                            <label htmlFor={k.toString()}
                                                                   className={"capitalize text-center text-sm block"}>{day}</label>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className={"flex flex-row"}>
                                                <input id={"default_availability"} name={"default_availability"}
                                                       checked={schedulerFormData.default_availability}
                                                       onChange={() => setSchedulerFormData({
                                                           ...schedulerFormData,
                                                           ["default_availability"]: !schedulerFormData.default_availability
                                                       })}
                                                       className={"mx-auto mr-2"} type="checkbox"/>
                                                <label htmlFor={"default_availability"}
                                                       className={"capitalize text-center text-sm block"}>Make this time
                                                    range your company's default</label>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="flex flex-row justify-center items-center pt-8 pb-4 w-full">
                                        <div className="flex flex-row items-center w-fit mr-auto">
                                            <ClockIcon className={"w-6 h-6 mr-2"}/>
                                            <span>Don't worry, you can set this per candidate or as a company default</span>
                                        </div>

                                        <Button
                                            onClick={() => scheduleInterviewBulk()}
                                            text={"Continue"}
                                            size={"md"} bg={"bg-blue-600"} color={"text-white"}/>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </Modal>
            <Modal
                isOpen={isOfferModalOpen}
                onRequestClose={() => setIsOfferModalOpen(false)}
                style={customStyles_3}
                contentLabel="Offer Modal"
            >
                {
                    isOfferModalOpen ?
                        <>
                            <div className="flex py-2 flex-row justify-between">
                                <div className="">
                                    <Title className={"w-full border-b border-black"}>Shortlist</Title>
                                    <div
                                        className="mx-1 rounded py-2 px-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-red-700 scrollbar-track-slate-300 w-full"
                                        style={{height: "calc(70vh -  145px)"}}>
                                        {
                                            state.forms.pool.map((user, key) =>
                                                selectedContenders.findIndex(cont => cont.user_id === user.user_id) > -1 ? <></> :
                                                    <div
                                                        className={"border border-gray-400 w-full rounded-lg mb-2 py-2 px-4 flex flex-row justify-start cursor-pointer transition relative " +
                                                            (user.interest === 1 ? "" :
                                                                user.interest === 2 ? "border-yellow-600 bg-yellow-50" :
                                                                    user.interest === 3 ? "border-green-600 bg-green-50" : "border-red-600 bg-red-50")}
                                                        key={key} onClick={() => {
                                                        if (selectedContenders.findIndex(i => i.user_id === user.user_id) === -1)
                                                            setSelectedContenders([...selectedContenders, user])
                                                    }}>
                                                        <div
                                                            className="h-12 overflow-hidden rounded-full bg-gray-400 w-12 flex justify-center items-center">
                                                            <img src={user.avatar}
                                                                 alt={user.first_name + " " + user.last_name}
                                                                 className="mx-auto rounded-full w-12"/>
                                                        </div>

                                                        <div className="py-1 pl-6 pr-2">
                                                            <h4 className={"capitalize font-bold"}>{user.first_name + " " + user.last_name}</h4>
                                                            <h4 className="capitalize">{JSON.parse(state.forms.project.details).requirements[user.job_id].job_title}</h4>
                                                        </div>
                                                    </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="">
                                    <Title className={"w-full border-b border-black"}>Approved Candidates</Title>
                                    <div
                                        className="mx-1 rounded py-4 px-4 overflow-y-scroll scrollbar-thin scrollbar-thumb-red-700 scrollbar-track-slate-300 w-full"
                                        style={{height: "calc(70vh -  145px)"}}>
                                        {
                                            selectedContenders.map((user, key) =>
                                                <div
                                                    className={"border border-gray-400 w-full rounded-lg mb-2 py-2 px-4 flex flex-row justify-start cursor-pointer transition relative " +
                                                        (user.interest === 1 ? "" :
                                                            user.interest === 2 ? "border-yellow-600 bg-yellow-50" :
                                                                user.interest === 3 ? "border-green-600 bg-green-50" : "border-red-600 bg-red-50")}
                                                    key={key}
                                                    onClick={() => setSelectedContenders(selectedContenders.filter(i => i.user_id !== user.user_id))}>
                                                    <div
                                                        className="h-16 overflow-hidden rounded-full bg-gray-400 16 flex justify-center items-center">
                                                        <img src={user.avatar}
                                                             alt={user.first_name + " " + user.last_name}
                                                             className="mx-auto rounded-full w-16"/>
                                                    </div>

                                                    <div className="py-1 pl-6 pr-2">
                                                        <h4 className={"capitalize font-bold"}>{user.first_name + " " + user.last_name}</h4>
                                                        <h4 className="capitalize">{JSON.parse(state.forms.project.details).requirements[user.job_id].job_title}</h4>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <Button onClick={proceedToStageFour} text={"Proceed"} bg={"bg-green-600"}
                                        color={"text-white"}
                                        className={"mx-auto"}/>
                            </div>
                        </> : null
                }
            </Modal>

            <HorizontalTrackComponent className={" border-b"}>
                {
                    props.loading ?
                        <>
                            <img src={spinner.img} alt="Loading..." className={"w-12 mx-auto animate-spin"}/>
                        </>
                        :
                        <>
                            {
                                JSON.parse(state.forms.project.details).requirements.map((item, key) =>
                                    <div
                                        onClick={() => setJobIdx(parseInt(key))}
                                        className={"border border-black px-2 py-4 rounded mr-4 cursor-pointer hover:shadow-xl transition duration-300 flex flex-row items-center " + (jobIdx === key ? "shadow-lg" : "")}
                                        key={key}>
                                        <h4 className={"mr-2"}>{item.job_title}</h4>
                                    </div>
                                )
                            }
                            <Button text={"Make Offers"} bg={"bg-green-600"} size={"md"}
                                    color={"text-white"}
                                    onClick={() => setIsOfferModalOpen(true)}/>
                        </>
                }
            </HorizontalTrackComponent>

            {
                jobIdx === -1 ?
                    <>
                    </>
                    :
                    <>
                        <div className="p-2">
                            <div className="w-full border-b grid grid-cols-3 gap-2 pb-2 mb-2">
                                <div className={""}>
                                    <h4>Job {jobIdx + 1}: {JSON.parse(state.forms.project.details).requirements[jobIdx] ? JSON.parse(state.forms.project.details).requirements[jobIdx].job_title : ""}</h4>
                                    <h4>Seniority: {JSON.parse(state.forms.project.details).requirements[jobIdx] ? JSON.parse(state.forms.project.details).requirements[jobIdx].seniority : ""}</h4>
                                    <h4>Salary
                                        Budget (Per Month): {formatPrice(JSON.parse(state.forms.project.details).requirements[jobIdx] ? JSON.parse(state.forms.project.details).requirements[jobIdx].salary_budget : "")}</h4>
                                    <h4>Job Link: <Link to={document.location.origin + '/jobs/' + project_id + '/' + parseInt(jobIdx + 1)}>Copy Link</Link></h4>
                                </div>

                                <div className={"flex flex-row flex-wrap"}>
                                    {
                                        JSON.parse(state.forms.project.details).requirements[jobIdx] ? JSON.parse(state.forms.project.details).requirements[jobIdx].skills.map((item, key) =>
                                            <div key={key}
                                                 className={"p-1 px-2 text-sm bg-gray-200 rounded h-fit mr-2 "}>
                                                {item.name}
                                            </div>
                                        ) : ""
                                    }
                                </div>

                                <div className="flex flex-col justify-center items-center">
                                    <div className="flex flex-row items-center">
                                        <h4 className={"mr-4"}>Contenders: {state.forms.pool.filter(contender => contender.job_id === (jobIdx)).length}</h4>
                                        <ArrowPathIcon onClick={init} className={"cursor-pointer w-5 h-5"}/>
                                    </div>
                                    <ButtonGroup>
                                        <Button text={"View JD"} bg={"bg-black"} size={"md"} color={"text-white"}
                                                onClick={() => setIsOpen(true)}/>
                                    </ButtonGroup>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-2">
                                {
                                    state.forms.pool
                                        .map(item => {
                                                return {
                                                    ...item,
                                                    relevancy: item.skills.map(s => s.skill_name).filter(a_v => JSON.parse(state.forms.project.details).requirements[jobIdx].skills.map(s => s.name).includes(a_v)).length
                                                }
                                            }) // creates relevancy property by skill alignment to project
                                        .filter(item => item.job_id === jobIdx) // filters by job_id
                                        .sort((a, b) => (a.skills.length > b.skills.length) ? -1 : 1)// sorts by number of skills
                                        .sort((a, b) => (a.relevancy > b.relevancy) ? -1 : 1) // sorts by skill relevancy
                                        .sort((a, b) => (a.interest > b.interest) ? -1 : 1) // sorts by indicated interest
                                        .map((contender, key) =>
                                            <TalentCard key={key} user={contender} idx={key}
                                                        scheduleSingle={scheduleSingle}
                                                        setContender={mountContender} cb={init}/>
                                        )
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    )
}


const TalentCard = ({user, setContender, cb, scheduleSingle}) => {
    const changeContenderInterestState = () => {
        clientChangeContenderState(user.project_talent_id)
            .then(result => {
                errorHandling(result.data)
                cb()
            })
    }

    const disqualifyContender = () => {
        window.confirm(`Are you sure you want to remove ${user.first_name + " " + user.last_name} from the list?`) &&
        clientDisqualifyContender(user.project_talent_id)
            .then(result => {
                errorHandling(result.data)

                switch (result.data.http_code) {
                    case 200:
                    case 201:
                        toast.success(user.first_name + " disqualified")
                        cb()
                        break;
                }
            })
    }

    return (
        <div
            className={"border-2 w-full rounded py-2 transition relative " +
                (user.interest === 1 ? "" : user.interest === 2 ? "border-yellow-600 bg-yellow-50" : user.interest === 3 ? "border-green-600 bg-green-50" : "border-red-600 bg-red-50")
            }>
            <div className="absolute top-4 left-4" onClick={changeContenderInterestState}>
                <EyeIcon className={"w-5 h-5 cursor-pointer transition " +
                    (user.interest === 1 ? "" : user.interest === 2 ? "text-yellow-600" : user.interest === 3 ? "text-green-600" : "text-red-600")
                }/>
            </div>
            <div
                style={{
                    background: `url(${user.avatar}) top/cover no-repeat`
                }}
                className="h-24 rounded-full bg-gray-400 w-24 mx-auto flex justify-center items-center">
            </div>

            <div className="pt-4 pb-2  px-2">
                <h4 className={"text-center capitalize font-bold"}>{user.first_name + " " + user.last_name}</h4>
                <div className="py-2 w-full flex flex-col justify-center items-center">
                    <ButtonGroup className={"mb-2 "}>
                        <Button
                            onClick={() => disqualifyContender()}
                            text={"Remove"} size={"xs"} bg={"bg-red-600"} color={"text-white"}/>
                        <Button onClick={() => setContender(user)} text={"View Data"} size={"xs"} bg={"bg-blue-600"}
                                color={"text-white"}/>
                    </ButtonGroup>
                    <Button bg={"bg-black"} color={"text-white"}
                            text={"Schedule Interview"}
                            size={"sm"}
                            onClick={() => scheduleSingle(user)}
                    />
                </div>
            </div>
        </div>
    )
}

export default StageThree