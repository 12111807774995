import {useState} from "react";
import {logoLarge, spinner} from "../../assets";
import {PlainTextInput} from "../../components/forms";
import {Button} from "../../components/buttons";
import {LockClosedIcon, UserIcon} from "@heroicons/react/24/outline";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {server_error_message} from "../../utils/strings";
import {endAuthenticating, logUser, startAuthenticating} from "../../store/reducers/authReducer";
import axiosInstance_API_V1 from "../../store/axiosInstance_API_V1";
import {toast} from "react-toastify";
import {CheckIfLoggedOut} from "../../utils/checkIfLoggedIn";

export const Login = () => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })

    const handleLoginUser = (e) => {
        e.preventDefault();
        dispatch(startAuthenticating())

        axiosInstance_API_V1.post("user/login/", formData)
            .then(
                result => {
                    switch (result.data.http_code) {
                        case 200:
                            toast.success("Login Successful")
                            localStorage.setItem('access_token', result.data.data.token)
                            localStorage.setItem('user_data', JSON.stringify(result.data.data.user))

                            dispatch(logUser({
                                token: result.data.data.token,
                                user: result.data.data.user
                            }))
                            break
                        case 400:
                            dispatch(endAuthenticating(result.data.error))
                            break
                        case 500:
                            dispatch(endAuthenticating(result.data.error))
                            break
                        default:
                            dispatch(endAuthenticating(server_error_message))
                            break;
                    }
                },
                error => {
                    dispatch(endAuthenticating(server_error_message))
                })
            .catch(err => {
                console.log(err)
                dispatch(endAuthenticating(server_error_message))
            })
            .finally(() => {
                setFormData({
                    email: "",
                    password: ""
                })
            })
    }

    return (
        <section className={"container mx-auto my-12"}>
            <CheckIfLoggedOut/>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="hidden lg:flex flex-col justify-center">
                    <img src={logoLarge.img} alt={logoLarge.alt} className={"mx-auto w-56"}/>
                </div>

                <form className="bg-red-600 py-24 lg:py-36 px-6">
                    <div className="py-6 relative">
                        <span className="absolute top-1/2 left-4 transform -translate-y-1/2">
                            <UserIcon className={"w-6 h-6 text-white"}/>
                        </span>
                        <PlainTextInput
                            value={formData.email}
                            onChange={(e) => setFormData({...formData, "email": e.target.value})}
                            className={"bg-transparent border-white text-white placeholder:text-white pl-12 py-5"}
                            placeholder={"Email"}/>
                    </div>
                    <div className="py-6 relative">
                        <span className="absolute top-1/2 left-4 transform -translate-y-1/2">
                            <LockClosedIcon className={"w-6 h-6 text-white"}/>
                        </span>
                        <PlainTextInput
                            value={formData.password}
                            onChange={(e) => setFormData({...formData, "password": e.target.value})}
                            className={"bg-transparent border-white text-white placeholder:text-white pl-12 py-5"}
                            placeholder={"Password"} type={"password"}/>
                    </div>

                    <div className="py-6">
                        <Button onClick={e => handleLoginUser(e)} bg={"text-black"} color={"bg-white"}
                                className={"hover:bg-gray-200"}>
                            {
                                state.auth.auth.authenticating ?
                                    <span className={""}>
                                        <img className={"animate-spin mx-auto"} src={spinner.img} alt={spinner.alt}
                                             style={{height: "25px"}}/>
                                    </span>
                                    :
                                    <span>
                                        Login
                                    </span>
                            }
                        </Button>
                    </div>

                    <div className="w-full text-right text-white flex flex-col">
                        <Link to={"/forgot-password"}>Forgot Password?</Link>
                        <Link to={"/sign-up"}>Don't have an account? Sign Up</Link>
                    </div>
                </form>
            </div>
        </section>
    )
}