import {useDispatch, useSelector} from "react-redux";

export const AdminContent_Clients = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)

    return(
        <>

        </>
    )
}