import {useDispatch, useSelector} from "react-redux";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {updatePublicSetting} from "../../../store/reducers/publicSettingsReducer";

export const AdminUsersLayout = () => {
    const dispatch = useDispatch()
    const state = useSelector(state => state)

    useEffect(() => {
        document.title = `User Management | TaaS Admin |TBO`

        dispatch(updatePublicSetting({name: "location", value: "users"}))
    }, [])

    return (
        <>
            <Outlet/>
        </>
    )
}