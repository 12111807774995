import {client_url, dashboard_url, projects_url} from "../../../utils/strings";
import {addLeadingZeros, parseDateToId, parseProjectState} from "../../../utils/helpers";
import {Link} from "react-router-dom";

export const ProjectViewCard = ({item,  className}) => {
    return (
        <Link className={"block"}
              to={client_url + dashboard_url + projects_url + "/" + parseDateToId(item.created_at) + addLeadingZeros(item.project_id)}
              className={"w-full border rounded-lg py-2 px-1 transition duration-300 cursor-pointer flex flex-row mb-3 " + className}>
            <div className="pl-2 pr-1 flex flex-col justify-center">
                <h4 className={"font-bold text-sm"}>{item.company_name} - {item.name}</h4>
                <h4 className={"font-light text-xs"}>{parseProjectState(item.state, 3)}</h4>
            </div>
        </Link>
    )
}