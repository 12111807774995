import {ChevronRightIcon, EnvelopeOpenIcon, MapIcon, PhoneIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import {public_url} from "../../utils/strings";
import {logoLarge} from "../../assets";

export const PublicFooter = () => {

    return (
        <>
            <footer className="w-full max-w-screen bg-gray-200 pt-20 pb-24">
                <div className="container mx-auto max-w-7xl  flex flex-col md:grid grid-cols-4 gap-2 md:px-4">
                    <div className="px-4 mb-8 md:mb-0">
                        <img src={logoLarge.img} alt={logoLarge.alt}/>
                    </div>

                    <div className="px-4 mb-8 md:mb-0">
                        <h4 className="font-bold text-center md:text-left">
                            About Us
                        </h4>

                        <ul className="flex flex-col font-light mt-8 justify-center">
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>About TBO TaaS</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>How to Use</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Request a Demo</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Blog</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Contact Us</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Privacy Policy</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>FAQ</p>
                            </Link>
                        </ul>
                    </div>

                    <div className="px-4 mb-8 md:mb-0">
                        <h4 className="font-bold text-center md:text-left">
                            Partner With Us
                        </h4>

                        <ul className="flex flex-col font-light mt-8 justify-center">
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Become a Partner</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Careers</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Referrals</p>
                            </Link>
                            <Link to={public_url} className="flex justify-left items-center mb-2">
                                <ChevronRightIcon className={"text-red-600 w-5 h-5"}/>
                                <p>Logistics</p>
                            </Link>
                        </ul>
                    </div>

                    <div className="px-4 mb-8 md:mb-0">
                        <div className="mb-8">
                            <h4 className="font-bold text-center md:text-left">
                                Contact Us
                            </h4>

                            <div className="flex flex-col font-light mt-8 justify-center">
                                <div className="mb-4 flex flex-row items-center">
                                    <div className="text-red-600 mr-2">
                                        <MapIcon className={"w-5 w-6"}/>
                                    </div>
                                    <div className="">
                                        <h4>
                                            Nathan Terrace, Plot 1 Babatunde Anjous Ave,
                                        </h4>
                                        <h4>
                                            Lekki Phase 1,
                                        </h4>
                                        <h4>
                                            Lagos, Nigeria
                                        </h4>
                                    </div>
                                </div>
                                <div className="mb-4 flex flex-row items-center">
                                    <div className="text-red-600 mr-2">
                                        <PhoneIcon className={"w-5 w-6"}/>
                                    </div>
                                    <div className="">
                                        <h4>+234 817 301 8955</h4>
                                        <h4>+234 1 6348889</h4>
                                    </div>
                                </div>
                                <div className="mb-4 flex flex-row items-center">
                                    <div className="text-red-600 mr-2">
                                        <EnvelopeOpenIcon className={"w-5 w-6"}/>
                                    </div>
                                    <h4>sales@tboisl.com</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}