import {Outlet} from "react-router-dom";
import {PublicHeader} from "../../components/navs/publicHeader";
import {PublicFooter} from "../../components/navs/PublicFooter";

export const PublicLayout = () => {

    return(
        <>
            <PublicHeader />
            <div className="relative  overflow-hidden">
                <Outlet />
            </div>
            <PublicFooter />
        </>
    )
}