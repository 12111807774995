import {useEffect, useState} from "react";
import {errorHandling} from "../../store/actions/actionHelpers";
import {updateForm} from "../../store/reducers/formsReducer";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clientFetchProject} from "../../store/actions/clientActions";
import {StageOne} from "./projects/stageOne";
import {StageTwo} from "./projects/stageTwo";
import {StageThree} from "./projects/stageThree";
import StageFive from "./projects/stageFive";
import StageFour from "./projects/StageFour";

const ClientProjectView = () => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    const {project_id} = useParams()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        clientFetchProject(parseInt(project_id.substring(8)))
            .then(result => {
                errorHandling(result.data)

                switch (result.data.http_code) {
                    case 201:
                    case 200:
                        dispatch(updateForm({
                            form: "project",
                            value: result.data.data
                        }))
                        setLoading(false)
                        break
                }
            })
    }, [project_id])


    return (
        <>
            {
                state.forms.project.state === 0 ?
                    <StageOne loading={loading}/>
                    : state.forms.project.state === 1 ?
                        <StageTwo loading={loading}/>
                        : state.forms.project.state === 2 ?
                            <StageThree loading={loading}/>
                            : state.forms.project.state === 3 ?
                                <StageFour loading={loading}/>
                                : state.forms.project.state === 4 ?
                                    <StageFive loading={loading}/>
                            : null
            }
        </>
    )
}

export default ClientProjectView