import {updatePublicSetting} from "../../store/reducers/publicSettingsReducer";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Title} from "../../components/misc";
import {emptyBox} from "../../assets";
import {ProjectViewCard} from "./components/projectViewCard";
import {Outlet, useLocation, useParams} from "react-router-dom";
import {errorHandling} from "../../store/actions/actionHelpers";
import {updateAdminData} from "../../store/reducers/adminDataReducer";
import {fetchClientProjects} from "../../store/actions/clientActions";
import {emptyForm, setFormLoading} from "../../store/reducers/formsReducer";

const ClientDashboard = () => {
    const dispatch = useDispatch()
    const {project_id} = useParams()
    const state = useSelector(state => state)
    const location = useLocation()
    const [filter, setFilter] = useState(1)

    useEffect(() => {
        dispatch(updatePublicSetting({name: "location", value: "dashboard"}))
        document.title = `TBO X ${state.auth.user_data.company_name} | Highly Talented Personnel On Demand - TBO TaaS`

    }, [])

    useEffect(() => {
        dispatch(setFormLoading())
        dispatch(emptyForm("project"))
    }, [location.pathname])

    useEffect(() => {
        fetchClientProjects()
            .then((res) => {
                errorHandling(res.data)
                switch (res.data.http_code) {
                    case 201:
                    case 200:
                        dispatch(updateAdminData({
                            section: "projects",
                            data: {
                                name: "data",
                                value: res.data.data
                            }
                        }))
                        break
                }
            })
    }, [])

    return (
        <div className={"flex flex-row"}>
            <div className="border-r min-h-screen__minus-header px-2 w-[350px]">
                <div className="border-b py-2 justify-between  items-center flex flex-row w-full">
                    <Title className={"text-red-600 font-bold"}>PROJECTS</Title>
                    <select name="projectFilter" id="projectFilter"
                            onChange={(e) => setFilter(parseInt(e.target.value))}
                            value={filter.toString()}
                            className={"border outline-none rounded-lg bg-transparent py-2 px-4"}>
                        <option className={"py-1"} value="0">All</option>
                        <option className={"py-1"} value="1">Open</option>
                        <option className={"py-1"} value="2">Closed</option>
                    </select>
                    <div>

                    </div>
                </div>

                <div className="w-full">
                    {
                        state.adminData.projects.data.length === 0 ?
                            <div className="border rounded-lg py-8 px-4 w-full mt-2">
                                <img src={emptyBox.img} alt={emptyBox.alt} className={"w-16 mx-auto mb-4"}/>

                                <h4 className={"text-center text-sm"}>There are no active projects</h4>
                            </div>
                            :
                            state.adminData.projects.data
                                .filter(item => {
                                    switch (filter) {
                                        case 0:
                                            return item
                                            break;
                                        case 1:
                                            if (parseInt(item.state) < 4)
                                                return item
                                            break;
                                        case 2:
                                            if (parseInt(item.state) > 3)
                                                return item
                                            break;
                                        default:
                                            return null
                                    }
                                })
                                .map((item, key) =>
                                <ProjectViewCard item={item} key={key} className={(project_id ? (parseInt(project_id.substring(8)) === item.project_id ? "bg-red-600 text-white shadow-xl" : "") : "")}/>
                            )
                    }
                </div>
            </div>
            <div className="w-full p-2 relative">
                <Outlet/>
            </div>
        </div>
    )
}

export default ClientDashboard