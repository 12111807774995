import Modal from 'react-modal';
import {XMarkIcon} from "@heroicons/react/20/solid";
import {Title} from "../../../components/misc";
import {ColoredSelect, ColoredTextArea, ColoredTextInput} from "../../../components/forms";
import {Button, ButtonGroup} from "../../../components/buttons";
import {useDispatch, useSelector} from "react-redux";
import {emptyForms, updateForm__L1} from "../../../store/reducers/formsReducer";
import {createNewProject__ExistingClient} from "../../../store/actions/adminActions";
import {errorHandling} from "../../../store/actions/actionHelpers";
import {useState} from "react";
import {toast} from "react-toastify";
import {forceRefresh} from "../../../store/reducers/adminDataReducer";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: "100%",
        maxWidth: "600px"
    },
};

export const NewProjectExCliForm = ({isOpen, setIsOpen, setIsClientOpen}) => {
    const dispatch = useDispatch()
    const [errors, setErrors] = useState(false)
    const state = useSelector(state => state)
    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
        dispatch(emptyForms())
        setErrors(false)
    }

    const processNewProject = (e) => {
        e.preventDefault()

        if (
            state.forms.project.client_id < 0 ||
            state.forms.project.project_name.length === 0
        ) {
            setErrors(true)
            toast.error("Please check highlighted fields for errors")
        } else {
            setErrors(false)

            createNewProject__ExistingClient(state.forms.project)
                .then(result => {
                    errorHandling(result.data)

                    switch (result.data.http_code) {
                        case 201:
                        case 200:
                            toast.success(result.data.message)
                            break
                    }
                }).finally(() => {
                dispatch(emptyForms())
                dispatch(forceRefresh())
                closeModal()
            })
        }
    }

    const updateForm = (form, name, value) => {
        dispatch(updateForm__L1({
            form: form,
            data: {
                name: name,
                value: value
            }
        }))
    }

    return (
        <div>
            <Modal
                isOpen={isOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="New Project Form"
            >
                <button className={"ml-auto block cursor-pointer w-fit"} onClick={closeModal}>
                    <XMarkIcon className={"w-6 h-6"}/>
                </button>

                <Title>New Project (Existing Client)</Title>
                <br/>
                <br/>
                <form onSubmit={e => processNewProject()}>
                    <div className="mb-8">
                        <ColoredSelect
                            value={state.forms.project.client_id || ""}
                            className={errors && state.forms.project.client_id < 0 ? "border-red-600" : ""}
                            onChange={e => updateForm("project", "client_id", e.target.value)}
                            label={"Client"}
                        >
                            <option value="">Select a Client</option>
                            {
                                state.adminData.clients.data.map((item, key) =>
                                    <option key={key} value={item.client_id}>
                                        <span>
                                            {item.company_name}
                                        </span>
                                    </option>
                                )
                            }
                        </ColoredSelect>
                    </div>
                    <div className="mb-8">
                        <ColoredTextInput
                            label={"Project Name"}
                            className={errors && state.forms.project.project_name.length === 0 ? "border-red-600" : ""}
                            value={state.forms.project.project_name || ""}
                            onChange={e => updateForm("project", "project_name", e.target.value)}
                        />
                    </div>

                    <ButtonGroup>
                        <Button
                            bg={"bg-red-600"}
                            color={"text-white"}
                            text={"Close"}
                            onClick={(e) => {
                                e.preventDefault()
                                closeModal()
                            }}
                        />
                        <Button
                            bg={"bg-yellow-500"}
                            color={"text-white"}
                            text={"New Client"}
                            onClick={(e) => {
                                e.preventDefault()
                                setIsClientOpen(true)
                                setIsOpen(false)
                            }}
                        />
                        <Button
                            bg={"bg-blue-600"}
                            color={"text-white"}
                            text={"Save"}
                            onClick={e => processNewProject(e)}
                        />
                    </ButtonGroup>
                </form>
            </Modal>
        </div>
    );
}