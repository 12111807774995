import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

const initialState = {
    access: localStorage.getItem('access_token'),
    auth: {
        authenticated: (localStorage.getItem('access_token') !== null && localStorage.getItem('access_token') !== undefined),
        authenticating: false
    },
    user_data: JSON.parse(localStorage.getItem("user_data")) || {}
}

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logUser: (state, action) => {
            return {
                ...state,
                access: action.payload.token,
                auth: {
                    ...state.auth,
                    ...{
                        authenticated: true,
                        authenticating: false
                    }
                },
                user_data:
                    {
                        ...state.user_data,
                        ...action.payload.user
                    }
            }
        },
        startAuthenticating: (state, action) => {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    authenticating: true
                }
            }
        },
        endAuthenticating: (state, action) => {
            toast.error(action.payload)
            return {
                ...state,
                auth: {
                    ...state.auth,
                    authenticating: false,
                    authenticated: false
                }
            }
        },
        terminateSession: (state, action) => {
            return initialState
        }
    }
})

export const {
    logUser,
    startAuthenticating,
    endAuthenticating,
    terminateSession
} = authSlice.actions
export default authSlice.reducer