import axiosInstance_API_V1 from "../axiosInstance_API_V1";

export const uploadImage = (formData) => {
    return axiosInstance_API_V1.post("/upload-image", formData, {headers: {'Content-Type': 'multipart/form-data'}})
}

export const uploadFile = (formData) => {
    return axiosInstance_API_V1.post("/upload-document", formData, {headers: {'Content-Type': 'multipart/form-data'}})
}

export const checkIfLoggedIn = () => {
    return axiosInstance_API_V1.get("/check-auth")
}

export const invalidateToken = () => {
    return axiosInstance_API_V1.get("/logout")
}