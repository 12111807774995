import axiosInstance_API_V1 from "../axiosInstance_API_V1";

export const createNewProject__ExistingClient = (formData) => {
    return axiosInstance_API_V1.post("/admin/projects/new/existing-client", formData)
}

export const createNewProject__NewClient = (formData) => {
    return axiosInstance_API_V1.post("/admin/projects/new/new-client", formData)
}

export const fetchAllProjects = () => {
    return axiosInstance_API_V1.get("/admin/projects/")
}

export const adminFetchProject = (id) => {
    return axiosInstance_API_V1.get("/admin/projects/" + id)
}

export const fetchAllClients = () => {
    return axiosInstance_API_V1.get("/admin/clients/")
}

export const updateProjectsRequirements = (id, data) => {
    return axiosInstance_API_V1.put("/admin/projects/" + id + "/requirement", data)
}

export const fetchTalentPoolDraft = (id, filters) => {
    return axiosInstance_API_V1.post("/admin/projects/" + id + "/talent-pool-draft", filters)
}

export const fetchAllSkills = () => {
    return axiosInstance_API_V1.get("/skills/")
}

export const fetchSkills = (data) => {
    return axiosInstance_API_V1.get("/list-skills/", {params: data})
}

export const editProjectState = (id, newState) => {
    return axiosInstance_API_V1.put("/admin/projects/" + id + "/change-state/" + newState)
}

export const logContenders = (id, contenders) => {
    return axiosInstance_API_V1.post("/admin/projects/" + id + "/contenders/", {
        contenders: contenders
    })
}

export const fetchAdmins = () => {
    return axiosInstance_API_V1.get("/super/list-admins")
}

export const fetchClients = (data) => {
    return axiosInstance_API_V1.get("/super/list-clients", {params: data})
}

export const fetchTalent = (data) => {
    return axiosInstance_API_V1.get("/super/list-talent", {params: data})
}

export const adminSearchTalent = (data) => {
    return axiosInstance_API_V1.get("/super/query-talent", {params: data})
}

export const adminSearchSkill = (data) => {
    return axiosInstance_API_V1.get("/super/query-skills", {params: data})
}

export const adminSearchAdmin = (data) => {
    return axiosInstance_API_V1.get("/super/query-talent", {params: data})
}

export const adminSearchClient = (data) => {
    return axiosInstance_API_V1.get("/super/query-client", {params: data})
}

export const advanceProjectToStageFour = (project_id, data) => {
    return axiosInstance_API_V1.put(`/admin/projects/${project_id}/make-offers`, data)
}

export const fetchStageFourContenders = (project_id) => {
    return axiosInstance_API_V1.get(`/admin/projects/${project_id}/stage-4-contenders`)
}

export const approveContenderS4 = (project_talent_id,  data) => {
    return axiosInstance_API_V1.put(`/admin/contender/${project_talent_id}/s4-approve`, data)
}

export const adminCreateSkills = (data) => {
    return axiosInstance_API_V1.post(`/admin/skills/new`, data)
}

export const adminDeleteSkill = (skill_id) => {
    return axiosInstance_API_V1.delete(`/admin/skills/delete/${skill_id}`)
}