import mtn_logo from "./imgs/MTN-Logo.jpg"
import atos_logo from "./imgs/atos.png"
import vfd_logo from "./imgs/vfd-logo.png"
import logo_large from "./imgs/logo_large-removebg-preview.png"
import logo from "./imgs/logo.svg"
import empty_box from "./imgs/box_empty.png"
import no_tasks from "./imgs/no_task.png"
import spinner_image from "./imgs/spinner.png"
import no_profile from "./imgs/no_profiles.png"
import error_404 from "./imgs/404-error.webp"
import hands_image from "./imgs/hands.webp"
import taas_preview from "./imgs/TAASpsd.png"
import royal_exchange_logo from "./imgs/ng-royale-logo.png"
import img_plc_logo from "./imgs/b1ffab1b-ng-img-logo.png"
import search_image from "./imgs/search.png"
import inq_inc_logo from "./imgs/INQ-Logo-FA-02-1.png"

export const logoLarge = {img: logo_large, alt: "TBO TaaS"}
export const tassLogo = {img: logo, alt: "TBO TaaS"}
export const emptyBox = {img: empty_box, alt: "Empty Box"}
export const noTasks = {img: no_tasks, alt: "No Tasks"}
export const spinner = {img: spinner_image, alt: "Loading..."}
export const noProfile = {img: no_profile, alt: "No Matching Users"}
export const error404 = {img: error_404, alt: "Error 404"}
export const handsImage = {img: hands_image, alt: "TBO TaaS Together"}
export const vfdLogo = {img: vfd_logo, alt: "VFD Microfinance"}
export const atosLogo = {img: atos_logo, alt: "Atos"}
export const mtnLogo = {img: mtn_logo, alt: "MTN"}
export const royalExchangeLogo = {img: royal_exchange_logo, alt: "MTN"}
export const imgPlcLogo = {img: img_plc_logo, alt: "MTN"}
export const inqIncLogo = {img: inq_inc_logo, alt: "MTN"}
export const taasPreview = {img: taas_preview, alt: "TBO TaaS Together"}
export const searchImage = {img: search_image, alt: "Select something valid"}