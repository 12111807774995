import {toast} from "react-toastify";
import {authentication_error_message, server_error_message} from "../../utils/strings";
export const errorHandling = (result) => {
    switch (result.http_code) {
        case 400:
            toast.error(result.message || server_error_message)
            toast.error(result.error || server_error_message)
            break
        case 401:
            toast.error(result.message || authentication_error_message)
            document.location.href = "/logout"
            break
        case 500:
            toast(server_error_message)
            break
    }
}