import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    projects: {
        page: 1,
        limit: 10,
        data: []
    },
    clients: {
        page: 1,
        limit: 10,
        data: []
    },
    interviews: {
        page: 1,
        limit: 10,
        data: []
    },
    forceUpdate: true
}

const adminDataReducer = createSlice({
    name: "adminData",
    initialState: initialState,
    reducers: {
        updateAdminData: (state, action) => {
            return {
                ...state,
                [action.payload.section]: {
                    ...state[action.payload.section],
                    [action.payload.data.name]: action.payload.data.value
                },
                forceUpdate: false
            }
        },
        forceRefresh: (state, action) => {
            return {
                ...state,
                forceUpdate: true
            }
        }
    }
})

export const {updateAdminData, forceRefresh} = adminDataReducer.actions
export default adminDataReducer.reducer